<template>
  <div>
    <el-dialog title="批量导入" width="800px" :visible.sync="showDialog" :close-on-click-modal="false"
      :close-on-press-escape="false" :before-close="closeImportData">
      <div class="display-flex fs-14" style="margin-top: -20px">
        <div @click="onSelectChapterClick" class="cursor-pointer align-items-center fs-14">
          <svg-icon iconClass="card_edit_box" class="w-20 h-20"></svg-icon>
          <span class="mg-l-10" v-if="Object.keys(chapter).length > 0">{{ chapter.name }}</span>
          <span class="mg-l-10 c-c" v-else>选择卡牌盒</span>
        </div>
        <div @click="onSelectModelClick" class="cursor-pointer mg-l-30 align-items-center">
          <svg-icon iconClass="card_template" class="w-20 h-20"></svg-icon>
          <span class="mg-l-10">{{ Object.keys(model).length > 0 ? model.name : '选择模版' }}</span>
        </div>
      </div>
      <div class="mg-t-20 mg-b-20 border-1 bdr-5 fs-12" :class="{ 'bdc-app-yellow': inputFocus, 'bdc-d': !inputFocus }">
        <el-input type="textarea" v-model="input" :autosize="{ minRows: 10, maxRows: 20 }" @focus="onInputFocus"
          @blur="onInputBlur" :placeholder="placeholder">
        </el-input>
        <div class="slice pd-10 justify-content-space-between">
          <div>
            <span>字段分隔符</span>
            <el-select v-model="sliceFieldType" class="w-90 mg-l-10" placeholder="输入字符" size="mini"
              @change="sliceFileChange">
              <el-option :value="1" label="换行" :key="1"></el-option>
              <el-option :value="2" label="自定义" :key="2"></el-option>
            </el-select>
            <el-input v-if="sliceFieldType == 2" v-model="sliceField" :minlength="1" :maxlength="3"
              class="w-80 mg-l-10" size="mini" />
            <span class="mg-l-10">卡片分隔符</span>
            <el-select v-model="sliceCardType" class="w-90 mg-l-10" placeholder="输入字符" size="mini"
              @change="sliceCardChange">
              <el-option :value="1" label="空一行"></el-option>
              <el-option :value="2" label="空两行"></el-option>
              <el-option :value="3" label="自定义"></el-option>
            </el-select>
            <el-input v-if="sliceCardType == 3" v-model="sliceCard" :minlength="1" :maxlength="3" placeholder="输入字符"
              class="w-80 mg-l-10" size="mini" />
          </div>
          <div>
            <el-button class="w-100" :loading="doPreview" type="primary" size="mini" @click="onPreviewClick">批量制卡
            </el-button>
          </div>
        </div>
      </div>
      <div>
        <div class="c-7 mg-b-20 mg-t-20">导入文件</div>
        <div class="display-flex">
          <div
            class="justify-content-center align-items-center bdr-5 w-120 pd-t-15 pd-b-15 border-1 bdc-c cursor-pointer"
            @click="onImportAnkiClick">
            <img class="w-25 h-25 mg-r-10" src="../assets/images/images_general_file_PC_light_anki@2x.png" />
            Anki
          </div>
          <div
            class="justify-content-center align-items-center bdr-5 w-120 pd-t-15 pd-b-15 border-1 bdc-c cursor-pointer mg-l-20"
            @click="onImportExcelClick">
            <img class="w-25 h-25 mg-r-10" src="../assets/images/excel-icon.png" />
            Excel
          </div>
          <div
            class="justify-content-center align-items-center bdr-5 w-120 pd-t-15 pd-b-15 border-1 bdc-c cursor-pointer mg-l-20"
            @click="onImportWechatClick">
            <img class="w-25 h-25 mg-r-10" src="../assets/images/images_general_file_PC_light_wechat-reader@2x.png" />
            微信笔记
          </div>
          <div
            class="justify-content-center align-items-center bdr-5 w-120 pd-t-15 pd-b-15 border-1 bdc-c cursor-pointer mg-l-20"
            @click="onImportOpmlClick('naotu')">
            <img class="w-22 h-22 mg-r-10 bdr-4" src="../assets/images/naotuopml.jpg" />
            脑图OPML
          </div>
          <div
            class="justify-content-center align-items-center bdr-5 w-150 pd-t-15 pd-b-15 border-1 bdc-c cursor-pointer mg-l-20"
            @click="onImportOpmlClick('dagang')">
            <img class="w-25 h-25 mg-r-10" src="../assets/images/dagangopml.jpg" />
            大纲笔记OPML
          </div>

        </div>
      </div>
    </el-dialog>

    <!-- 导入Anki -->
    <import-anki ref="importAnki" @onBackClick="showDialog = true"></import-anki>
    <!-- 导入Excel -->
    <import-excel ref="importExcel" @onBackClick="showDialog = true" @onSelectModelClick="onSelectModelClick"
      @onSelectChapterClick="onSelectChapterClick" :model="model" :chapter="chapter"></import-excel>
    <import-wechat ref="importWechat" @onBackClick="showDialog = true" @onPreview="onInnerPreview"
      @onSelectChapterClick="onSelectChapterClick" :chapter="chapter"></import-wechat>
    <import-opml ref="importOpml" @onBackClick="showDialog = true" :model="model" :chapter="chapter"
      @onPreview="onInnerPreview" @onSelectModelClick="onSelectModelClick" @onSelectChapterClick="onSelectChapterClick">
    </import-opml>
    <select-model ref="selectModel" 
      @selectModel="selectModel" 
      @openImportAnki="onImportAnkiClick"
      :showSelectModel.sync="isShowSelectModel"
      @updateShowSelectModel="updateShowSelectModel" 
      v-if="isShowSelectModel">
    </select-model>
    <chapter-options ref="chapterOptions" @selectChapter="selectChapter" :chapter="chapter"></chapter-options>
    <import-preview :chapter="chapter" :cardList="cardList" :chapterList="chapterList" ref="importPreview"
      @onItemClick="onCardClick" @onConfirmImportClick="onConfirmImportClick"
      @onSelectChapterClick="onSelectChapterClick"></import-preview>
    <simple-card-viewer ref="simpleCardViewer" :card-list="cardList"></simple-card-viewer>
  </div>
</template>

<script>
import SimpleCardViewer from './SimpleCardViewer'
import ImportAnki from "./ImportAnki";
import ImportWechat from "./ImportWechat";
import ImportOpml from "./ImportOpml";
import SelectModel from "./SelectModel";
import ChapterOptions from "./ChapterOptions";
import { showMessage } from '../libs/message';
import ImportPreview from "./ImportPreview";
import request from '../libs/api.request';
import ImportExcel from "./ImportExcel";
import { showScrollBar } from '../libs/util';

export default {
  name: "ImportData",
  components: {
    ImportExcel,
    ImportPreview,
    ChapterOptions,
    ImportAnki,
    ImportWechat,
    SelectModel,
    ImportOpml,
    SimpleCardViewer
  },
  data() {
    return {
      importType: '',
      showDialog: false,
      isShowSelectModel:false,
      model: {
        flds: [
          {
            "ord": 0,
            "name": "正面",
            'placeholder': '请输入正面'
          },
          {
            "ord": 1,
            "name": "背面",
            'placeholder': '请输入背面'
          },
        ],
        name: "知识点",
        modelId: 1,
        hideTitle: true
      },
      chapter: {},
      input: "",
      inputFocus: false,
      doPreview: false,
      sliceCardType: 1,
      sliceFieldType: 2,
      sliceField: '|',
      sliceCard: '',
      cardList: [],
      chapterList: [],
    }
  },
  computed: {
    placeholder() {
      // let placeholder = "示例：\n\n";
      let placeholder = "";
      let slice = '';
      let flds = this.model.flds.map(v => v.name);
      if (this.sliceFieldType == 1) {
        slice = "\n";
      } else {
        slice = this.sliceField.length == 0 ? ' ' : this.sliceField;
      }
      placeholder += flds.join(slice);
      if ((/Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent))) {
        placeholder = '';
      } else {
        if (this.sliceCardType == 1) {
          placeholder += "\n\n";
        } else if (this.sliceCardType == 2) {
          placeholder += "\n\n\n";
        } else {
          placeholder += this.sliceCard;
        }
        if (this.sliceCard.length > 0 || this.sliceCardType < 3) {
          placeholder += flds.join(slice);
        }
      }
      return placeholder;
    }
  },
  methods: {
    setOptions() {
      localStorage.setItem('importOptions', JSON.stringify({
        sliceFieldType: this.sliceFieldType,
        sliceCardType: this.sliceCardType,
        sliceField: this.sliceField,
        sliceCard: this.sliceCard,
        model: this.model
      }))
    },
    updateShowSelectModel(v){
      this.isShowSelectModel = v
    },
    sliceFileChange() {
      // this.setOptions()
    },
    sliceCardChange() {
      // this.setOptions()
    },
    closeImportData(done) {
      showScrollBar()
      done()
    },
    onConfirmImportClick() {
      if (!this.chapter.chapterId) {
        this.onSelectChapterClick();
        return;
      }

      if (this.importType === 'importWechat') {
        this.$refs.importWechat.onConfirmImportClick();
        return;
      } else if (this.importType === 'importOpml') {
        this.$refs.importOpml.onConfirmImportClick();
        return;
      }

      let loading = this.$loading({
        lock: true,
        text: '正在导入，请稍等！',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.5)'
      });

      request.request({
        url: '/card/importByInput',
        method: 'post',
        data: {
          modelId: this.model.modelId,
          chapterId: this.chapter.chapterId,
          input: this.input,
          sliceCardType: this.sliceCardType,
          sliceFieldType: this.sliceFieldType,
          sliceField: this.sliceField,
          sliceCard: this.sliceCard,
        },
      }).then(data => {
        loading.close();
        showMessage({ message: '导入成功！', type: 'success' })
        setTimeout(() => {
          location.reload();
        }, 500);
      }).catch(e => {
        loading.close();
        showMessage({ message: e.message, type: 'error' })
      })
    },
    onCardClick(index) {
      this.$refs.simpleCardViewer.open(index)
    },
    onInputFocus() {
      this.inputFocus = true;
    },
    onInputBlur() {
      this.inputFocus = false;
    },
    open() {
      this.showDialog = true;
    },
    onImportAnkiClick() {
      this.$refs.importAnki.open();
      if(this.isShowSelectModel){
        this.isShowSelectModel = false
      }
      setTimeout(() => {
        this.showDialog = false;
      }, 200);
    },
    onImportExcelClick() {
      this.$refs.importExcel.open();
      setTimeout(() => {
        this.showDialog = false;
      }, 200);
    },
    onImportWechatClick() {
      this.$refs.importWechat.open();
      setTimeout(() => {
        this.showDialog = false;
      }, 200);
    },
    onImportOpmlClick(type) {
      this.$refs.importOpml.open(type);
      setTimeout(() => {
        this.showDialog = false;
      }, 200);
    },
    onSelectChapterClick() {
      this.$refs.chapterOptions.open();
    },
    onSelectModelClick() {
      this.isShowSelectModel = true
      // this.$refs.selectModel.open();
    },
    selectModel(model) {
      if (model.modelId == 4 || model.modelId == 5) {
        showMessage({ message: `批量导入不适用该模板`, type: "error" });
        return;
      }
      this.model = model;
    },
    selectChapter(chapter) {
      this.chapter = chapter;
      this.$refs.chapterOptions.close();
    },
    onPreviewClick() {
      if (this.doPreview) {
        return;
      }
      if (this.input.length == 0) {
        showMessage({ message: '请输入内容', type: 'error' })
      } else {
        this.doPreview = true;
        request.request({
          url: '/card/importPreviewByInput',
          method: 'post',
          data: {
            modelId: this.model.modelId,
            input: this.input,
            sliceCardType: this.sliceCardType,
            sliceFieldType: this.sliceFieldType,
            sliceField: this.sliceField,
            sliceCard: this.sliceCard,
          },
        }).then(data => {
          this.setOptions()
          this.doPreview = false;
          this.cardList = data.list;
          this.$refs.importPreview.open();
        }).catch(e => {
          this.doPreview = false;
          showMessage({ message: e.message, type: 'error' })
        })
      }
    },
    onInnerPreview(data) {
      this.importType = data.importType;
      this.cardList = data.cardList;
      this.chapterList = data.chapterList;
      this.$refs.importPreview.open();
    }
  },
  created() {
    if (localStorage.getItem('importOptions')) {
      const { sliceCard, sliceCardType, sliceField, sliceFieldType, model } = JSON.parse(localStorage.getItem('importOptions'))
      this.sliceCard = sliceCard
      this.sliceCardType = sliceCardType
      this.sliceField = sliceField
      this.sliceFieldType = sliceFieldType
      this.model = model
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep .el-dialog {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-top: 0 !important;
  overflow: hidden;
  border-radius: 8px;
}

::v-deep .el-textarea__inner {
  border: none !important;
  resize: none !important;
}

::v-deep .slice .el-input--mini .el-input__inner {
  text-align: center !important;
}
</style>
