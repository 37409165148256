<template>
  <el-dialog :title="title" :visible.sync="showDialog" width="552px" :close-on-click-modal="false">
    <div class="card-name">
      <el-input placeholder="请输入名称" v-model="chapter.name"></el-input>
    </div>
    <div class="upload-container">
      <div class="upload-select">选择/上传封面</div>
      <div class="upload">
        <el-upload accept=".jpeg,.png,.jpg" class="avatar-uploader" :headers="uploadHeaders" :show-file-list="false"
          :action="apiBaseUrl + '/common/uploadImage'" :on-success="handleAvatarSuccess" :disabled="isDisabled">
          <svg-icon iconClass="make_card_add" class="upload-add-icon"></svg-icon>
        </el-upload>
        <div class="upload-item" v-for=" (item, index) in chapterCoveList" :key="index"
          @click="selectChapterCover(item, index)" :class="currentIndex === index ? 'selected' : ''">
          <img :src="item" class="upload-img" />
        </div>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleCancel" class="cancel">取 消</el-button>
      <el-button type="primary" @click="finishMakeCard" class="finish">完 成</el-button>
    </span>
  </el-dialog>
</template>
<script>
import request from '../libs/api.request'
import { showMessage } from '../libs/message';
import { mapGetters } from 'vuex';

export default {
  props: {
    title: {
      type: String,
      default: '新建卡牌盒'
    },

    isFirstLevel: {
      type: Boolean,
      default: false
    },
    // 当前点击的卡牌的盒
    currentChapter: {
      type: Object,
      default: () => { }
    },
    isDisabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      chapterCoveList: [],
      showDialog: false,
      currentIndex: 0,
      chapter: {
        name: '',
        chapterId: '',
        parentId: '',
        image: '',
        position: ''
      },
      uploadHeaders: {},
      apiBaseUrl: request.baseUrl,
    };
  },
  methods: {
    async getChapterCover() {
      const res = await request.request({ url: '/chapter/covers' })
      this.chapterCoveList = res.list;
      if (this.chapter.image == '') {
        this.chapter.image = this.chapterCoveList[0]
      }
    },
    open() {
      this.showDialog = true;
      if (this.chapterCoveList.length === 0) {
        this.getChapterCover();
      }
    },
    selectChapterCover(img, index) {
      this.chapter.image = img
      this.currentIndex = index
    },
    finishMakeCard() {
      if(this.chapter.name.trim().length === 0) {
        return showMessage({ message: '请输入卡牌盒名称', type: 'error' })
      }
      if (!this.isVip && this.vipLimitData && this.vipLimitData.chapterCount.limit <= this.vipLimitData.chapterCount.use) {
        this.showDialog = false
        this.$store.commit('changeShowIntroduceVip', true)
        return
      }
      this.setChapterData()
      request.request({
        url: "/chapter/save",
        method: 'post',
        data: {
          ...this.chapter
        }
      }).then(res => {
        this.showDialog = false;
        if (this.title === '新建卡牌盒') {
          showMessage({ message: '新增卡牌盒成功', type: 'success' })
        } else {
          showMessage({ message: '修改卡牌盒成功', type: 'success' })
        }
        this.$emit('saveAddChapter', this.chapter)
        if (!this.isVip) {
          this.$store.dispatch('modifyUserInfo')
        }
      }).catch(err => {
        showMessage({ message: err.message, type: 'error' })
      })
    },
    setChapterData() {
      if (this.isFirstLevel) {
        this.chapter.parentId = 0
        this.chapter.chapterId = new Date().getTime() + '001'
      } else {
        this.chapter.chapterId = this.currentChapter.chapterId
        this.chapter.parentId = 0
      }
    },
    handleCancel() {
      this.showDialog = false
    },
    handleAvatarSuccess(res, file) {
      if (res.code === 0) {
        this.chapterCoveList.unshift(res.data.url);
        this.chapter.image = this.chapterCoveList[0]
        this.currentIndex = 0
      }
    }
  },
  computed: {
    ...mapGetters(['vipLimitData', 'isVip'])
  },
  watch: {
    currentChapter() {
      if (Object.keys(this.currentChapter).length > 0) {
        this.chapter.name = this.currentChapter.name
        this.chapter.image = this.currentChapter.image;
        this.chapter.chapterId = this.currentChapter.chapterId
        let index = this.chapterCoveList.indexOf(this.currentChapter.image);
        if (index === -1) {
          this.chapterCoveList.unshift(this.currentChapter.image);
        } else {
          this.currentIndex = index;
        }
      } else {
        this.chapter = {
          name:''
        }
      }
    }
  },
  created() {
    this.uploadHeaders = {
      authorization: this.$cookies.get('authToken')
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/variable.scss';

.upload-container {
  width: 100%;

  .upload-select {
    font-size: 14px;
    font-weight: bold;
    color: $base-color;
    margin-left: 5px;
    margin-top: 32px;
    margin-bottom: 10px;
  }

  .upload {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .upload-add-icon {
      width: 48px;
      height: 48px;
    }

    .upload-item {
      position: relative;
      margin-bottom: 20px;
      width: 108px;
      height: 140px;

      &.selected {
        border: 2px solid $theme-color;
        border-radius: 8px;
      }

      &:hover {
        cursor: pointer;
      }

      .upload-img {
        width: 100%;
        height: 100%;
        border-radius: 8px;
        object-fit: cover;
      }
    }
  }
}

::v-deep .avatar-uploader {
  width: 108px;
  height: 144px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: 1px solid rgba(32, 32, 32, 0.16);
}

::v-deep .el-dialog__header {
  padding: 32px 20px 0 24px;
}

::v-deep .el-dialog__body {
  box-sizing: border-box;
  padding: 32px 24px 0px;
  height: 460px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 0px;
  }
}

::v-deep .el-dialog__title {
  font-weight: bold;
  color: #202020;
  font-size: 16px;
}

::v-deep .el-dialog__headerbtn {
  top: 32px;
  font-size: 24px;
}

::v-deep .el-dialog__footer {
  padding: 0 24px 32px;

  .el-button+.el-button {
    margin-left: 16px;
  }
}

::v-deep .el-button {
  width: 112px;

  &.finish {
    span {
      color: #fff;
    }
  }
}

.cancel {
  background-color: #fff;
  color: #202020;
}

::v-deep .el-dialog {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-top: 0 !important;
  overflow: hidden;
  border-radius: 12px;
}
</style>
