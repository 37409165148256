<template>
  <el-dialog :visible.sync="_modelVisible" center append-to-body width="60%" :close-on-click-modal="false">
    <div class="header" slot="title">
      <div class="modelName">{{model.name}}</div>
      <div class="display-flex w-200 justify-content-center">
        <div class="header-tab" v-for="(item,index) in tabList" :key="item.id" :class="currentIndex===index?'active':''"
          @click="onTabClick(item,index)">
          {{item.name}}
        </div>
      </div>
      <div class="w-200"></div>
    </div>
    <div class="model-edit-container">
      <div class="model-edit-content" v-show="currentIndex === 0">
        <div class="display-flex justify-content-space-between model-item">
          <div class="model-name">模版命名</div>
          <div class="model-card-num" v-if="cardNum > 0">{{cardNum}}张卡片正在使用</div>
        </div>
        <el-input v-model="modelName" class="model-ipt" placeholder="输入模版名称"></el-input>
        <div class="line mg-t-24"></div>
        <div class="model-item mg-t-12">
          <div class="model-name">正面模版</div>
          <el-input type="textarea" :rows="4" placeholder="输入正面模版代码" v-model="tmpls[0].qfmt">
          </el-input>
        </div>
        <div class="line mg-t-20"></div>
        <div class="model-item mg-t-12">
          <div class="model-name">背面模版</div>
          <el-input type="textarea" :rows="4" placeholder="输入背面模版代码" v-model="tmpls[0].afmt">
          </el-input>
        </div>
        <div class="line mg-t-20"></div>
        <div class="model-item mg-t-12">
          <div class="model-name">样式</div>
          <el-input type="textarea" :rows="5" placeholder="输入样式代码" v-model="css">
          </el-input>
        </div>
      </div>
      <div class="file-content" v-show="currentIndex === 1">
        <draggable :list="flds" group="people" forceFallback="true"
          style="width:100%;padding-left: 12px;" animation="1000" @start="onStart" @end="onEnd"
          filter=".forbid" fallbackTolerance="100" class="display-flex flex-wrap justify-content-space-between">
          <div class="file-item" v-for="(fild,index) in flds">
            <el-input v-model="fild['name']" :ref="fild.ord" :id="fild.ord"></el-input>
            <img src="../../../assets/images/close.png" alt="" class="close-img" @click.stop="onDeleteFild(fild,index)">
          </div>
          <div class="file-item forbid" @click="onAddClick">
            <svg-icon iconClass="catelog_add" class="w-18 h-18"></svg-icon>
          </div>
          <div class="file-item forbid" style="visibility: hidden;"></div>
        </draggable>
      </div>
      <div class="model-edit-footer">
        <div class="desc">注：更改模板并保存后，将会影响相关联的卡片，请谨慎操作</div>
        <el-button @click="onSaveClick" class="w-120 bdr-8 my-btn">{{model.modelId?'保存':'创建'}}</el-button>
      </div>
    </div>
  </el-dialog>

</template>
<script>
import request from '../../../libs/api.request';
import draggable from 'vuedraggable'
import { showMessage } from '../../../libs/message';

export default {
  props: {
    modelVisible: {
      type: Boolean,
      default: false
    },
    model: {
      type: Object,
      default: {}
    }
  },
  components: {
    draggable
  },
  data() {
    return {
      currentIndex: 0,
      tabList: [{ name: '模版代码', id: 1 }, { name: '字段管理', id: 2 }],
      modelName: "",
      cardNum: 0,
      flds: [
        {
          name: '正面',
          ord: 0
        },
        {
          name: '背面',
          ord: 1
        },
      ],
      css: '',
      tmpls: [
        {
          name: '',
          afmt: `{{FrontSide}}
<hr>
{{背面}}`,
          ord: 0,
          qfmt: '{{正面}}'
        }
      ],
    }
  },
  methods: {
    onStart(e) { },
    onEnd(e) {
      this.flds = e.to.__vue__.list
    },
    onAddClick() {
      this.flds.push({
        name: '',
        ord: this.flds.length
      })
      this.$nextTick(() => {
        // 为新创建的节点自动获得焦点
        this.$refs[this.flds.length -1][0].focus()
      })
    },
    judgeFildIsEmpty() {
      let isEmpty = false
      for (let i = 0; i < this.flds.length; i++) {
        if (this.flds[i].name.trim().length === 0) {
          showMessage({ message: `第${i + 1}个字段不能为空`, type: 'error' })
          isEmpty = true
          break
        }
      }
      return isEmpty
    },
    onSaveClick() {
      if (this.modelName.trim() == '') return showMessage({ message: '模版名称不能为空', type: 'error' })
      if (this.flds.length === 0) return showMessage({ message: '至少需要一个字段', type: 'error' })
      if (this.judgeFildIsEmpty()) return
      let desc = '', title = ''
      if (this.model.modelId) {
        if (this.cardNum === 0) {
          desc = '确定修改模版？'
        } else {
          desc = '以上修改将影响' + this.cardNum + '张卡片,是否确定修改？'
        }
        title = '修改模版'
      } else {
        desc = '确定创建新的模版？'
        title = '创建模版'
      }
      this.$confirm(desc, title, {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            request.request({
              url: '/model/saveModel',
              method: 'post',
              data: {
                modelId: this.model.modelId || new Date().getTime() + '001',
                tmpls: JSON.stringify(this.tmpls),
                flds: JSON.stringify(this.flds),
                css: this.css,
                name: this.modelName
              }
            }).then(res => {
              this.$store.dispatch('getModelList')
              if (this.model.modelId) {
                showMessage({ message: '修改成功！', type: 'success' })
              } else {
                showMessage({ message: '创建成功！', type: 'success' })
              }
              this._modelVisible = false
              done();
            }).catch(e => {
              done();
              showMessage({ message: e.message, type: 'error' })
            })
          } else {
            done();
          }
        }
      })

    },
    onDeleteFild(fild, index) {
      if (this.flds.length > 1) {
        if (fild.name.trim().length === 0) {
          this.flds.splice(index, 1)
        } else {
          this.$confirm(`确定删除字段 ${fild.name}`, '删除字段', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            beforeClose: (action, instance, done) => {
              if (action === 'confirm') {
                this.flds.splice(index, 1)
                showMessage({ message: '删除成功！', type: 'success' })
                done()
              } else {
                done();
              }
            }
          })
        }
      } else {
        return showMessage({ message: '至少需要一个字段', type: 'error' })
      }
    },
    onTabClick(item, index) {
      if (this.currentIndex !== index) {
        this.currentIndex = index
      }
    }
  },
  computed: {
    _modelVisible: {
      get() {
        return this.modelVisible
      },
      set(v) {
        this.$emit('updateModelVisible', v)
      }
    }
  },
  mounted() {
    if (Object.keys(this.model).length > 0) {
      this.flds = this.model.flds
      this.css = this.model.css
      this.modelName = this.model.name
      this.tmpls = this.model.tmpls
      request.request({
        url: '/model/cardNum',
        params: {
          modelId: this.model.modelId
        }
      }).then(res => {
        this.cardNum = res.cardNum
      })
    }
  },
}
</script>

<style scoped lang="scss">
@import '../../../assets/css/mixin.scss';
.my-btn {
  border: none;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .modelName {
    font-size: 16px;
    color: #ffa800;
    justify-content: flex-start;
    width: 200px;
    @include clamp(1)
  }

  .header-tab {
    font-size: 14px;
    color: #202020;
    padding: 6px 10px;
    border-radius: 4px;

    &.active {
      background-color: #ffa800;
      color: #fff;
    }

    &:hover {
      cursor: pointer;
    }
  }
}

.model-edit-container {
  .model-edit-content {
    padding-bottom: 80px;

    .model-item {

      .model-name {
        font-size: 16px;
        color: #202020;
        font-weight: 600;
        margin-bottom: 10px;
      }

      .model-card-num {
        font-size: 12px;
        color: #ffa800;
      }
    }
  }

  .line {
    width: 100%;
    height: 1px;
    background-color: rgba(32, 32, 32, 0.04);
    margin-bottom: 12px;
  }

  .model-edit-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #fff;
    padding: 12px 24px;
    border-top: 1px solid rgba(32, 32, 32, 0.04);
    border-radius: 8px;

    .desc {
      color: #202020;
      opacity: .6;
    }
  }

  .file-content {
    display: flex;
    // padding: 0 24px;
    flex-wrap: wrap;
    padding-bottom: 100px;

    .file-item {
      font-size: 14px;
      color: #202020;
      border: 1px dashed #ccc;
      text-align: center;
      width: 30%;
      height: 54px;
      line-height: 54px;
      border-radius: 6px;
      margin-right: auto;
      margin-bottom: 30px;
      position: relative;

      &:hover {
        .close-img {
          display: block;
        }
      }

      .close-img {
        position: absolute;
        right: -10px;
        top: -10px;
        width: 20px;
        height: 20px;
        display: none;
      }

      &:hover {
        cursor: pointer;
      }
    }
  }
}

::v-deep .el-input__inner {
  border: none;
  text-align: center;

  &:hover {
    cursor: pointer !important;
  }
}

::v-deep .model-ipt .el-input__inner {
  text-align: left !important;
  padding-left: 0;
  color: #202020;
}

::v-deep .el-textarea__inner {
  background-color: #f7f7f7;
  border: none;

  &::placeholder {
    color: #202020;
    opacity: .6;
  }
}

// ::v-deep .el-dialog__body {
//   padding: 22px 20px 0;
// }
</style>