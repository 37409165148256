<template>
  <div>
    <div class="display-flex position-relative" style="min-height: 36px">
      <template v-if="tools">
        <div class="position-relative" v-for="tool, name in tools">
          <a class="position-relative" @mousedown="mousedown"
             @mouseup="mouseup" :data-name="tool.name"
             :style="name !== 'sourceEditing' && tools.sourceEditing.active ? 'opacity: .3' : ''"
             style="user-select:none;">
            <el-popover :disabled="showTableOption" :content="tool.title" trigger="hover" popper-class="my-popper" :close-delay="0"
                        placement="bottom-start">
              <img :src="tool.icon" class="w-28 h-28 bdr-2 pd-6 mg-r-6 tool-img" :class="{ active: tool.active }"
                   slot="reference">
            </el-popover>
          </a>
          <div v-if="tool.name === 'insertTable' && showTableOption" style="left: -20px;z-index: 99999999999"
               class="position-absolute">
            <div class="triTop bg-f shadow-2 bdr-20 pd-10 w-160 fs-12">
              <div class="mg-l-10">插入表格</div>
              <div class="mg-t-10 mg-l-10">
                <el-input-number size="mini" :max="20" :min="1" v-model="tableRow"
                                 class="w-100 mg-r-5"></el-input-number>
                行
              </div>
              <div class="mg-t-5 mg-l-10">
                <el-input-number size="mini" :max="10" :min="1" v-model="tableColumn"
                                 class="w-100 mg-r-5"></el-input-number>
                列
              </div>
              <div class="justify-content-center mg-t-10">
                <a class="c-app-yellow cursor-pointer" @click="confirmInsertTable">确定</a>
                <a class="c-app-yellow cursor-pointer mg-l-20" @click="showTableOption = false">取消</a>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <a v-for="tool, name in tmpTools" class="position-relative tool" :data-name="tool.name"
           style="opacity: .3">
          <el-popover :content="tool.title" trigger="hover" popper-class="my-popper" :close-delay="0"
                      placement="bottom-start">
            <img :src="tool.icon" class="w-28 h-28 bdr-2 pd-6 mg-r-6 tool-img" slot="reference">
          </el-popover>
        </a>
      </template>
      <div class="expansion-icon" @click.stop="onClick" v-show="showToggleIcon">
        <svg-icon :iconClass="$store.state.isFullScreen ? 'close_expansion' : 'expansion'"></svg-icon>
      </div>
    </div>
    <input @change="fileChange" id="editUploadImage" type="file" accept=".jpeg,.jpg,.png,.git" style="display: none"/>
  </div>
</template>

<script>

import EditorToolUtil from '../../libs/EditToolbarUtil'

export default {
  name: "EditorToolbar",
  props: {
    tools: {
      type: Object,
      default: () => {
      }
    },
    showToggleIcon: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      tmpTools: EditorToolUtil.makeToolObj(),
      showTableOption: false,
      tableRow: 3,
      tableColumn: 3,
    }
  },
  methods: {
    confirmInsertTable() {
      this.showTableOption = false;
      this.$emit('toolClick', {
        tool: this.tools['insertTable'],
        params: {rows: this.tableRow, columns: this.tableColumn}
      })
    },
    onClick() {
      this.$store.commit('setToggleFullScreen', !this.$store.state.isFullScreen)
    },
    mousedown(e) {
      if (e && e.preventDefault)
        e.preventDefault();
      else
        window.event.returnValue = false;
      return false;
    },
    mouseup(e) {
      if (e && e.preventDefault)
        e.preventDefault();
      else
        window.event.returnValue = false;
      if (e.currentTarget.dataset.name === 'image') {
        document.getElementById('editUploadImage').click();
      } else if (e.currentTarget.dataset.name === 'insertTable') {
        this.showTableOption = !this.showTableOption;
      } else {
        this.$emit('toolClick', {tool: this.tools[e.currentTarget.dataset.name]})
      }
      return false;
    },
    fileChange(e) {
      console.log(e.currentTarget.files);
      let files = Array.from(e.currentTarget.files);
      this.$emit('toolClick', {tool: this.tools['image'], params: {file: files}})
    }
  }
}
</script>

<style scoped lang="scss">
.active {
  background-color: rgba(100, 100, 100, 0.15);
}

.expansion-icon {
  position: absolute;
  right: 24px;
  z-index: 9;

  &:hover {
    cursor: pointer;
  }

}

.tool-img {
  &:hover {
    cursor: pointer;
    background-color: rgba(100, 100, 100, 0.15);
  }
}
</style>
<style>
.my-popper {
  padding: 6px 12px;
  width: max-content;
  min-width: 10px;
  height: 28px;
  background: #fff;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.2000);
  border-radius: 4px;
  border: none;
  color: #202020;
  z-index: 999999;
  font-size: 12px;
}

::v-deep .el-popover {
  z-index: 99999999;
}

.triTop:before {
  content: "";
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid #ffffff;
  position: absolute;
  top: -8px;
  left: 26px;
}

</style>
