<template>
  <div>
    <el-dialog width="800px" :visible.sync="showDialog" :close-on-click-modal="false"
               :close-on-press-escape="false"
               :before-close="beforeDialogClose">
      <div slot="title">
        <span class="cursor-pointer" @click="onBackClick">返回上一步</span>
      </div>
      <div class="display-flex fs-14" style="margin-top: -20px">
        <div @click="onSelectChapterClick" class="cursor-pointer align-items-center fs-14">
          <!-- <img
              src="../assets/images/icons_action_PC_light_card-box_24pt@2x.png"
              class="w-20 h-20"
          /> -->
          <span class="mg-l-10" v-if="Object.keys(chapter).length>0">{{chapter.name}}</span>
          <span class="mg-l-10 c-c" v-else>选择卡牌盒</span>
        </div>
      </div>
      <div class="mg-t-20 mg-b-20 border-1 bdr-5 fs-12" :class="{'bdc-app-yellow':inputFocus,'bdc-d':!inputFocus}">
        <el-input
            type="textarea"
            v-model="input"
            :autosize="{ minRows: 10, maxRows: 20 }"
            @focus="onInputFocus"
            @blur="onInputBlur"
            placeholder="复制微信笔记到此">
        </el-input>
        <div class="slice pd-10 justify-content-space-between">
          <div></div>
          <div>
            <el-button class="w-100" :loading="doPreview" type="primary" size="mini" @click="onPreviewClick">批量制卡
            </el-button>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {showMessage} from '../libs/message';
import request from '../libs/api.request';
import {hideScrollBar, showScrollBar} from "../libs/util";

export default {
  name: "ImportWechat",
  data() {
    return {
      showDialog: false,
      model: {
        flds: [
          {
            "ord": 0,
            "name": "内容"
          },
        ],
        name: "微信笔记",
        modelId: 50
      },
      input: "",
      inputFocus: false,
      doPreview: false,
      cardList: []
    }
  },
  props: {
    chapter: {
      type: Object,
      default: () => {
      }
    },
  },
  computed: {},
  methods: {
    onBackClick() {
      this.close();
      this.$emit('onBackClick');
    },
    onConfirmImportClick() {
      if (!this.chapter.chapterId) {
        this.onSelectChapterClick();
        return;
      }

      let loading = this.$loading({
        lock: true,
        text: '正在导入，请稍等！',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.5)'
      });

      request.request({
        url: '/card/importByWechatInput',
        method: 'post',
        data: {
          chapterId: this.chapter.chapterId,
          input: this.input,
        },
      }).then(data => {
        loading.close();
        showMessage({message: '导入成功！', type: 'success'})
        setTimeout(() => {
          location.reload();
        }, 500);
      }).catch(e => {
        loading.close();
        showMessage({message: e.message, type: 'error'})
      })
    },
    onCardClick(index) {
      this.$refs.simpleCardViewer.open(index)
    },
    onInputFocus() {
      this.inputFocus = true;
    },
    onInputBlur() {
      this.inputFocus = false;
    },
    open() {
      hideScrollBar()
      this.showDialog = true;
    },
    close() {
      showScrollBar();
      this.showDialog = false;
    },
    onSelectChapterClick(e) {
      this.$emit('onSelectChapterClick', e);
    },

    onPreviewClick() {
      if (this.doPreview) {
        return;
      }
      if (this.input.length == 0) {
        showMessage({message: '请输入内容', type: 'error'})
      } else {
        this.doPreview = true;
        request.request({
          url: '/card/importPreviewByWechatInput',
          method: 'post',
          data: {
            input: this.input,
          },
        }).then(data => {
          this.doPreview = false;
          this.$emit('onPreview', {cardList: data.list, chapterList: data.chapterList, importType: 'importWechat'})
        }).catch(e => {
          this.doPreview = false;
          showMessage({message: e.message, type: 'error'})
        })
      }
    },
    beforeDialogClose(done){
      showScrollBar();
      done()
    }
  }
}
</script>

<style scoped>
::v-deep .el-dialog {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-top: 0 !important;
  overflow: hidden;
  border-radius: 8px;
}

::v-deep .el-textarea__inner {
  border: none !important;
  resize: none !important;
}

::v-deep .slice .el-input--mini .el-input__inner {
  text-align: center !important;
}
</style>
