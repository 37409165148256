<template>
  <div>
    <el-drawer title="选择卡牌盒" :visible.sync="selectChapterDrawer" direction="ltr" :size="300" :before-close="close">
      <div v-if="chapterOptions.length == 0" class="text-align-center c-7">
        正在加载...
      </div>
      <div v-else>
        <el-tree :data="chapterOptions" node-key="chapterId" :props="treeProps" :expand-on-click-node="false" icon-class="el-icon-arrow-right">
          <div slot-scope="{ node, data }" @click="nodeClick(data)" style="flex:1" >
            <div class="align-items-center pd-t-10 pd-b-10 c-0">
              <svg-icon :iconClass="data.chapterId === 100 ? 'quick_box' : 'folder'" class="w-16 h-16 mg-r-10"
                v-if="node.level === 1">
              </svg-icon>
              <div class="fs-14 text-ellipsis-1" style="color:#202020"
                :class="{'c-app-yellow': data.chapterId == (chapter ? (chapter.sParentId || chapter.chapterId) : 0) }">
                {{ data.name }}
              </div>
            </div>
          </div>
        </el-tree>
      </div>
      <div class="position-absolute b-0 l-0 r-0 bg-f fs-14" style="border-top: 1px solid #cccccc">
        <div class="pd-20 cursor-pointer align-items-center" @click="onCreateNewChapterClick">
        <svg-icon iconClass="add_folder" class="w-16 h-16 mg-r-10"></svg-icon>新建卡牌盒
        </div>
      </div>
    </el-drawer>
    <make-card ref="makeChapter" :isFirstLevel="true" @saveAddChapter="saveAddChapter"></make-card>
  </div>
</template>

<script>

import request from '../libs/api.request'
import MakeCard from "./MakeCard";

export default {
  name: "ChapterOptions",
  components: { MakeCard },
  props: {
    chapter: {
      type: Object,
      default: () => {
      }
    }
  },
  data() {
    return {
      chapterOptions: [],
      selectChapterDrawer: false,
      treeProps: {
        children: 'childList',
        label: 'name'
      }
    }
  },
  methods: {
    close() {
      this.selectChapterDrawer = false;
    },
    open() {
      this.selectChapterDrawer = true;
      if (this.chapterOptions.length == 0) {
        this.getChapterList();
      }
    },
    getChapterList() {
      request.request({
        url: 'chapter/allTree',
      }).then(data => {
        this.chapterOptions = data.list;
      })
    },
    nodeClick(chapter) {
      this.$emit('selectChapter', chapter);
    },
    onCreateNewChapterClick() {
      this.$refs.makeChapter.open();
    },
    saveAddChapter() {
      this.$store.dispatch('modifyChapterList')
      this.$store.dispatch('modifyAllChapterList')
      this.getChapterList();
    }
  }
}
</script>

<style>
.el-tree-node__content:hover {
  background: rgba(123, 123, 128, 0.08) !important;
}
.el-tree-node:focus>.el-tree-node__content {
  background: #f7f7f7 !important;

}
.el-drawer__body {
  padding-bottom: 70px;
}

.el-collapse-item__header {
  border: none !important;
}

.el-tree-node__content {
  flex: 1;
  height: 44px;
  padding-top: 12px;
  padding-bottom: 11px;
}
</style>
