<template>
  <div class="study-view" v-show="show">
    <div class="study-view-box">
      <div class="study-view-content not-select" :style="handleClienSize()">
        <!-- style="max-width: 744px" -->
        <div class="study-card-head">
          <div class="card-title">
            <div class="title">{{ card.chapterName }}</div>
          </div>
          <div class="card-title">
            <span class="num">{{ currentIndex + 1 }}/{{ cardList.length }}</span>
          </div>
        </div>
        <!-- 卡牌内容 -->
        <div class="card-container">
          <iframe
              :srcdoc="srcdoc"
              frameborder="0"
              style="width: 100%; border: none;border-radius: 12px;overflow: hidden;"
              :style="iframeHeight"
          ></iframe>
        </div>
        <div class="show-answer" v-if="!model.noQfmt" :style="{ backgroundColor: bgcColor }">
          <div
              class="show-answer-text"
              @click="showAnswer"
          >{{ cardStatus === 'qfmt' ? '显示答案' : '显示正面' }}
          </div>
        </div>
      </div>
      <div class="study-nav not-select" v-show="!isFullScreen">
        <div class="nav-close" @click="ClosePreview">
          <svg-icon iconClass="cardviwer_close" class="w-24 h-24"></svg-icon>
        </div>
        <div class="nav-screen" @click="triggerNavStatus">
          <svg-icon iconClass="cardviwer_fullscreen" class="w-24 h-24"></svg-icon>
        </div>
      </div>
      <div class="full-screen-btn not-select" v-if="isFullScreen" @click="triggerNavStatus">
        <svg-icon iconClass="cardviwer_nofullscreen" class="w-24 h-24"></svg-icon>
      </div>
      <!-- 上一页 -->
      <div
          class="study-toggle-icon pre not-select"
          v-if="cardList.length > 0 && currentIndex > 0"
          @click="showPreCard"
      >
        <svg-icon iconClass="cardviwer_left_arrow" class="w-24 h-24 mg-l-16"></svg-icon>
      </div>
      <!-- 下一页 -->
      <div
          class="study-toggle-icon next not-select"
          v-if="cardList.length > 0 && currentIndex < cardList.length - 1"
          @click="showNextCard"
      >
        <svg-icon iconClass="cardviwer_right_arrow" class="w-24 h-24 mg-l-32"></svg-icon>
      </div>
    </div>
  </div>
</template>
<script>
import request from "../libs/api.request";
import CardRender from '../libs/CardRender';

export default {
  name: "CardViewer",
  props: {
    // 预览的卡牌组列表
    cardList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      modelList: {},
      model: {},
      bgcColor: '',
      isFullScreen: false,
      cardStatus: "qfmt", //qfmt 正面  afmt 背面
      show: false, //
      clienHeight: 0,
      leftAndRightWidth: 152,
      padHeight: 48, //上下padding的和
      navHeight: 56, // 底部nav的高度
      headAndFootHeiht: 130, //72 头部 80 底部按钮
      card: {}, // 当前卡牌盒子的卡牌数据
      screenWidth: "",
      srcdoc: "",
      currentIndex: 0, // 当前预览的卡牌的索引
    };
  },
  methods: {
    // 上一张
    showPreCard() {
      if (this.currentIndex !== 0) {
        this.currentIndex--;
        this.card = this.cardList[this.currentIndex];
        this.cardStatus = "qfmt";
        this.rendHtml();
      }
    },
    // 下一张
    showNextCard() {
      if (this.currentIndex < this.cardList.length - 1) {
        this.currentIndex++;
        this.card = this.cardList[this.currentIndex];
        this.cardStatus = "qfmt";
        this.rendHtml();
      }
    },
    // 卡牌内容渲染
    rendHtml() {
      this.srcdoc = CardRender(this.cardStatus, this.card, this.model);
      this.$nextTick(() => {
        setTimeout(() => {
          let body = document.getElementsByTagName('iframe')[0].contentWindow.document.getElementsByTagName('body')[0];
          if (body == undefined) {
            this.bgcColor = 'rgba(255, 255, 255)'
            return
          }

          let color = window.getComputedStyle(body, null).backgroundColor;
          if (color == 'rgba(0, 0, 0, 0)') {
            color = 'rgba(255, 255, 255)'
          }
          this.bgcColor = color
        }, 200)
      })
    },
    showAnswer() {
      if (this.cardStatus === 'afmt') {
        this.cardStatus = 'qfmt'
      } else {
        this.cardStatus = 'afmt'
      }
      this.rendHtml();
    },
    triggerNavStatus() {
      this.isFullScreen = !this.isFullScreen;
    },
    ClosePreview() {
      this.srcdoc = '';
      this.show = false;
    },
    async getModelDetail() {
      if (this.modelList[this.card.modelId] === undefined || this.modelList[this.card.modelId].css === null) {
        this.modelList[this.card.modelId] = await request.request({
          url: "model/detail",
          params: {
            modelId: this.card.modelId
          }
        });
      }
      this.model = this.modelList[this.card.modelId];
      return true;
    },
    handleResize() {
      this.screenWidth = window.document.documentElement.clientWidth;
      this.clienHeight = window.document.documentElement.clientHeight;
    },
    handleClienSize() {
      if (this.isFullScreen) {
        let height = this.clienHeight - this.padHeight;
        let width = (this.clienHeight - this.padHeight) * 0.84;
        if (width > this.screenWidth - 152) {
          width = this.screenWidth - 152;
        }
        return `height:${height}px;width:${width}px;backgroundColor:${this.bgcColor}"`
      } else {
        let height = this.clienHeight - this.padHeight - this.navHeight;
        let width = (this.clienHeight - this.padHeight - this.navHeight) * 0.84;
        if (width > this.screenWidth - 152) {
          width = this.screenWidth - 152;
        }
        return `height:${height}px;width:${width}px;backgroundColor:${this.bgcColor}`
      }
    },

    // keydon用来处理有ctrl + 有默认事件的
    handleKeyUp(e) {
      if (e.keyCode === 37) {
        // 键盘左键
        this.showPreCard()
        // e.preventDefault()
        // return false
      } else if (e.keyCode === 39) {
        // 键盘右键
        this.showNextCard()
        // e.preventDefault()
        // return false
      }
    },
    async open(index) {
      this.currentIndex = parseInt(index);
      this.card = this.cardList[this.currentIndex];
      await this.getModelDetail();
      this.rendHtml();
      this.show = true;
    }
  },
  computed: {
    iframeHeight() {
      if (this.isFullScreen) {
        return `height: ${this.clienHeight - this.padHeight
          }px`;
      } else {
        return `height: ${this.clienHeight -
        this.padHeight -
        this.navHeight
          }px`;
      }
    },
  },
  watch: {
    screenWidth(n) {
      this.screenWidth = n;
      this.handleClienSize();
    },
    show(n) {
      if (n) {
        window.addEventListener("resize", this.handleResize, true);
        window.addEventListener('keyup', this.handleKeyUp, true)
      } else {
        console.log('close');
        window.removeEventListener('keyup', this.handleKeyUp, true)
        window.removeEventListener("resize", this.handleResize, true);
      }
    }
  },
  mounted() {
    this.clienHeight = window.document.documentElement.clientHeight;
    this.screenWidth = window.document.documentElement.clientWidth;
  },
}
</script>
<style lang="scss" scoped>
@import "@/assets/css/mixin.scss";

.study-view {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: 100%;
  background-color: #f7f7f5;
  padding-top: 24px;
  padding-bottom: 24px;
  z-index: 99999;

  .study-view-box {
    position: relative;
    padding: 0 76px;

    .study-view-content {
      position: relative;
      // max-width: 744px !important;
      margin: 0 auto;
      background-color: #fff;
      border-radius: 12px;
      margin-bottom: 24px;

      .study-card-head {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 9;
        width: 100%;
        height: 48px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 12px 24px;
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;

        .card-title {
          display: flex;
          align-items: center;

          .img {
            width: 20px;
            height: 20px;
            opacity: 0.8;

            &:hover {
              cursor: pointer;
            }
          }

          .title {
            @include clamp(1);
            font-size: 14px;
            color: rgba(0, 0, 10, 0.6);
            opacity: 0.8;
          }

          .num {
            margin-right: 16px;
            font-size: 16px;
            color: rgba(0, 0, 10, 0.4);
            line-height: 16px;
            opacity: 0.8;
          }
        }
      }

      .card-container {
        padding: 34px 24px -1px 24px;
        position: relative;
        overflow: hidden;

        .more-menu {
          position: absolute;
          top: 2px;
          right: 0;
          z-index: 99;
          width: 192px;
          background-color: #fff;
          border-radius: 8px;
          box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.1);
          overflow: hidden;

          .more-menu-item {
            display: flex;
            align-items: center;
            height: 48px;
            font-size: 14px;
            color: #202020;

            &:hover {
              cursor: pointer;
              background-color: #fff8e7;
              color: #ffb700;
            }
          }
        }
      }

      .show-answer {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 80px;
        line-height: 80px;
        text-align: center;
        font-size: 16px;
        color: #00000a;
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;

        .show-answer-text {
          &:hover {
            color: #ffb700;
          }
        }

        border-top: 1px solid rgba(32, 32, 32, 0.06);

        &:hover {
          cursor: pointer;
        }
      }
    }

    .study-nav {
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 56px;
      background: rgba(255, 255, 255, 0.5);
      display: flex;
      justify-content: center;

      .nav-icon {
        display: flex;
        align-items: center;
        justify-content: space-around;
        width: 744px;

        .nav-icon-item {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 20%;
          border-radius: 8px;

          &:hover {
            cursor: pointer;
          }

          .nav-img {
            width: 24px;
            height: 24px;
          }

          .flag-box {
            position: absolute;
            bottom: 56px;
            width: 180px;
            height: 192px;
            background-color: #fff;
            border-radius: 8px;
            box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.1);

            .flag-item {
              width: 100%;
              height: 48px;
              display: flex;
              align-items: center;
              padding: 0 12px 0 16px;

              &:hover {
                background: #fff8e7;
              }

              .flag-img {
                width: 16px;
                height: 16px;
              }

              .flag-name {
                color: #202020;
                margin-left: 14px;
                margin-right: auto;

                &.active {
                  font-weight: 600;
                }
              }

              .check-img {
                width: 24px;
                height: 24px;
                text-align: right;
              }
            }
          }
        }
      }

      .nav-close {
        position: absolute;
        left: 32px;
        top: 50%;
        transform: translateY(-50%);
        width: 24px;
        height: 24px;

        &:hover {
          cursor: pointer;
        }

        img {
          width: 100%;
          height: 100%;
        }
      }

      .nav-screen {
        position: absolute;
        right: 32px;
        top: 50%;
        transform: translateY(-50%);
        width: 24px;
        height: 24px;

        &:hover {
          cursor: pointer;
        }

        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    .full-screen-btn {
      position: absolute;
      bottom: 24px;
      right: 24px;
      width: 40px;
      height: 40px;
      background-color: #fff;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        cursor: pointer;
      }

      .full-screen-img {
        width: 24px;
        height: 24px;
      }
    }

    .study-toggle-icon {
      width: 72px;
      height: 144px;
      background: rgba(0, 0, 0, 0.08);
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      display: flex;
      align-items: center;

      &:hover {
        cursor: pointer;
      }

      &.pre {
        left: 0;
        border-radius: 0 72px 72px 0;
      }

      &.next {
        right: 0;
        border-radius: 72px 0 0 72px;
      }
    }
  }
}

iframe::-webkit-scrollbar {
  width: 0;
}
</style>
