<template>
  <el-dialog width="600px" :visible.sync="showDialog"
             :close-on-press-escape="false"
             :close-on-click-modal="false"
             :before-close="beforeDialogClose"
  >
    <div slot="title">
      <span class="cursor-pointer" @click="onBackClick">返回上一步</span>
    </div>

    <div v-if="step < 2">
      <div class="upload-container">
        <el-upload
            drag
            accept=".opml"
            :on-progress="onUploadProgress"
            :on-success="onUploadSuccess"
            :before-upload="beforeUpload"
            :on-error="onUploadError"
            :show-file-list="false"
            :headers="uploadHeaders"
            :action="apiBaseUrl + '/study/uploadOpml'"
            ref="upload"
            :disabled="uploading">
          <img
              v-if="fileType === 'dagang'"
              class="bdr-8"
              style="width: 76px;height: 76px;margin-top: 30px;"
              src="../assets/images/dagangopml.jpg"/>
          <img
              v-else
              class="bdr-8"
              style="width: 76px;height: 76px;margin-top: 30px;"
              src="../assets/images/naotuopml.jpg"/>
          <div class="el-upload__text upload-text">
            将.opml文件拖动到此或点击上传
          </div>
        </el-upload>
      </div>
      <div class="upload-title" v-if="step === 1">
        <span>上传文件中</span>
      </div>
      <div class="upload-progress" v-if="step === 1">
        <div class="progress">
          <span>{{progress}}%</span>
        </div>
        <div class="h-50 align-items-center">
          <svg-icon iconClass="importing_close" class="w-30 h-30 cursor-pointer" v-if="step == 1" @click="cancelUpload"></svg-icon>
        </div>
      </div>
      <el-progress v-if="step === 1" :color="'#FFD60A'" :show-text="false" :stroke-width="6"
                   :percentage="progress"></el-progress>
    </div>
    <div v-else>
      <div>
        <el-button size="mini" @click="step = 0">重新选择文件</el-button>
        <span v-if="uploadFile" class="mg-l-20">{{uploadFile.name}}</span>
      </div>
      <div class="display-flex fs-14 mg-t-20">
        <div @click="onSelectChapterClick" class="cursor-pointer align-items-center fs-14">
          <svg-icon iconClass="card_edit_box" class="w-20 h-20"></svg-icon>
          <span class="mg-l-10" v-if="Object.keys(chapter).length>0">{{chapter.name}}</span>
          <span class="mg-l-10 c-c" v-else>选择卡牌盒</span>
        </div>
        <div @click="onSelectModelClick" class="cursor-pointer mg-l-30 align-items-center">
          <svg-icon iconClass="card_template" class="w-20 h-20"></svg-icon>
          <span class="mg-l-10">{{Object.keys(model).length> 0 ?model.name: '选择模版'}}</span>
        </div>
      </div>
      <div class="mg-t-20">
        <div class="text-strong">请选择层级对应关系</div>
        <div class="c-7 mg-t-10">
          <div>你目前需要考虑2个问题 ：</div>
          <div>①大纲笔记/脑图里有多个目录层级，选择哪些目录对应到惊叹卡牌盒里的目录？</div>
          <div>②大纲文件哪些目录层级是作为闪卡的内容？</div>
          <div>请选择对应到闪卡的字段，点下一步开始导入</div>
        </div>
      </div>
      <div class="mg-t-20 display-flex">
        <div class="w-130 text-strong">OPML</div>
        <div class="w-100"></div>
        <div class="text-strong">惊叹闪卡</div>
      </div>
      <div class="mg-t-10 display-flex w-400" v-for="(deep,index) in depth" :key="index">
        <div class="w-130">{{deep}}级目录</div>
        <div class="w-100">→</div>
        <div class="">
          <el-select size="mini" v-model="map[index]" placeholder="请选择(可选)"
                     @change="onSelectChange(index)" clearable
          >
            <el-option-group
                v-for="group,index in options"
                :key="group.label"
                :label="group.label">
              <el-option
                  v-for="item in group.options"
                  :key="item.value"
                  :label="index === 0 ? item.label:'  '+item.label"
                  :value="item.value">
              </el-option>
            </el-option-group>
          </el-select>
        </div>
      </div>
      <div class="mg-t-50">
        <el-button class="w-100" size="mini" type="primary" @click="onNextStepClick" :loading="doPreview">下一步
        </el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import request from '../libs/api.request'
import {hideScrollBar, showScrollBar} from "../libs/util";
import {showMessage} from '../libs/message'

export default {
  props: {
    model: {
      type: Object,
      default: () => {
      }
    },
    chapter: {
      type: Object,
      default: () => {
      }
    },
  },
  data() {
    return {
      showDialog: false,
      uploadHeaders: {},
      apiBaseUrl: request.baseUrl,
      progress: 0,
      step: 0,
      depth: 1,
      checkCode: '',
      uploadFile: null,
      uploading: false,
      doPreview: false,
      fileType: '',
      file: {},
      map: ['', '', '', '', ''],
      options: [{
        label: '目录',
        options: [{
          value: '_dir_',
          label: '目录'
        }]
      }, {
        label: '字段',
        options: []
      }],
    }
  },
  watch: {
    model() {
      console.log("model change");
      this.refreshOptions();
    }
  },
  methods: {
    onConfirmImportClick() {
      if (!this.chapter.chapterId) {
        this.onSelectChapterClick();
        return;
      }

      let loading = this.$loading({
        lock: true,
        text: '正在导入，请稍等！',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.5)'
      });

      request.request({
        url: '/card/importByOpml',
        method: 'post',
        data: {
          checkCode: this.checkCode,
          modelId: this.model.modelId,
          chapterId: this.chapter.chapterId,
          map: JSON.stringify(this.map)
        },
      }).then(data => {
        loading.close();
        showMessage({message: '导入成功！', type: 'success'})
        setTimeout(() => {
          location.reload();
        }, 500);
      }).catch(e => {
        loading.close();
        showMessage({message: e.message, type: 'error'})
      })
    },
    onNextStepClick() {
      if (this.doPreview) {
        return;
      }

      this.doPreview = true;
      request.request({
        url: '/card/importPreviewByOpml',
        method: 'post',
        data: {
          checkCode: this.checkCode,
          modelId: this.model.modelId,
          map: JSON.stringify(this.map)
        },
      }).then(data => {
        this.doPreview = false;
        this.$emit('onPreview', {cardList: data.list, chapterList: data.chapterList, importType: 'importOpml'})
      }).catch(e => {
        this.doPreview = false;
        showMessage(e)
      })
    },
    onSelectChange(index) {
      if (this.map[index] === '_dir_') {
        for (let i = 0; i < index; i++) {
          if (this.map[i] !== '_dir_') {
            this.map[i] = '_dir_';
          }
        }
      } else {
        for (let i = index + 1; i < this.map.length; i++) {
          if (this.map[i] === '_dir_') {
            this.map[i] = '';
          }
        }
      }
    },
    refreshOptions() {
      this.options[1].options = [];
      let map = [];
      for (let i = 0; i < this.depth; i++) {
        map.push('');
      }
      this.map = map;
      this.model.flds.forEach(v => {
        this.options[1].options.push({
          value: v.name,
          label: v.name,
        })
      })
    },
    onSelectChapterClick() {
      this.$emit('onSelectChapterClick')
    },
    onSelectModelClick() {
      this.$emit('onSelectModelClick')
    },
    onBackClick() {
      if (!this.uploading) {
        this.close();
        this.$emit('onBackClick');
      }
    },
    open(type) {
      this.step = 0;
      hideScrollBar();
      this.showDialog = true;
      this.fileType = type;
      if (this.model.flds) {
        this.refreshOptions();
      }
    },
    close() {
      showScrollBar();
      this.showDialog = false;
    },
    beforeDialogClose(done) {
      if (this.step == 1 && this.uploadFile) {
        this.$refs.upload.abort(this.uploadFile);
        this.uploadFile = null;
      }
      showScrollBar();
      done();
    },
    cancelUpload() {
      if (this.step == 1 && this.uploadFile) {
        this.$refs.upload.abort(this.uploadFile);
        this.uploadFile = null;
        this.step = 0;
        this.progress = 0;
        this.uploading = false
      }
    },
    // 上传之前
    beforeUpload(file) {
      this.step = 1;
      this.uploadFile = file;
      console.log('上传前', file);
    },
    // 上传中
    onUploadProgress(event, file) {
      this.uploading = true
      this.progress = parseInt(event.percent)
    },
    // 上传成功
    onUploadSuccess(response, file) {
      this.progress = 0;
      this.uploading = false;
      if (response.code === 0) {
        this.checkCode = response.data.checkCode;
        this.depth = response.data.depth;
        this.refreshOptions();
        this.step = 2;
      } else if (response.code === 1) {
        this.step = 0;
        showMessage({message: response.message, type: 'warning'})
      }
    },
    // 上传失败
    onUploadError() {
      this.step = 0;
      this.progress = 0;
      this.uploading = false
      showMessage({message: '上传失败，请重试或检查网络', type: 'error'})
    },
    starImportProgress() {
      this.step = 2;
      this.progress = 0;
      this.checkImportProgress();
    },
    checkImportProgress() {
      request.request({
        url: '/study/checkImport',
        params: {checkCode: this.checkCode}
      }).then((data) => {
        if (data.status == 1) {
          this.progress = data.progress;
          this.checkImportProgress();
        } else if (data.status == 2) {
          this.progress = 100;
          this.$store.commit('setModelList', data.modelList);
          this.$store.dispatch('modifyAllChapterList')
          this.$store.dispatch('modifyChapterList')
          this.$store.dispatch('modifyTotalChapterData')
          setTimeout(() => {
            if (data.chapterId > 0) {
              this.$confirm('导入完成！', '提示', {
                confirmButtonText: '前往学习',
                cancelButtonText: '继续导入',
                type: 'success',
                beforeClose: (action, instance, done) => {
                  this.step = 0;
                  this.progress = 0;
                  this.uploading = false
                  done();
                  if (action === 'confirm') {
                    if (this.$route.path.indexOf('/home/studyPlatform/chapter/') !== -1) {
                      location.href = '/home/studyPlatform/chapter/' + data.chapterId;
                    } else {
                      this.$router.push({path: '/home/studyPlatform/chapter/' + data.chapterId});
                    }
                  }
                }
              });
            } else {
              this.$alert('导入完成', {
                callback: () => {
                  location.reload();
                }
              });
            }
          }, 800);//给个1秒钟延时进度条效果
        } else if (data.status == -1) {//导入错误
          this.step = 0;
          this.progress = 0;
          this.uploading = false
          showMessage({message: data.errMsg, type: 'warning'})
        }
      }).catch(() => {
        this.uploading = false
        setTimeout(() => {
          this.checkImportProgress();
        }, 2000)
      })
    }
  },
  created() {
    this.uploadHeaders = {
      authorization: this.$cookies.get('authToken')
    }
  }
}
</script>

<style lang="scss" scoped>

.upload-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  .wechat-book {
    border-radius: 12px;
    width: 240px;
    height: 172px;
    background: #f2f2f2;
    padding: 0 82px;

    &:hover {
      cursor: pointer;
    }

    .wechat-book-text {
      .text {
        font-size: 12px;
        color: rgba(32, 32, 32, 0.4);
      }
    }
  }

  ::v-deep .el-upload {
    border: 1px dashed transparent;
    box-sizing: border-box;
    border-radius: 12px;
    background: linear-gradient(white, white) padding-box,
    repeating-linear-gradient(
            -45deg,
            transparent 0,
            transparent 0.4em,
            #ccc 0,
            #ccc 0.85em
    );

    &:hover {
      border: 1px dashed transparent;
      background: linear-gradient(white, white) padding-box,
      repeating-linear-gradient(
              -45deg,
              white 0,
              white 0.4em,
              #ffb700 0,
              #ffb700 0.85em
      );
    }
  }

  ::v-deep .el-upload-dragger {
    border-radius: 12px;
    width: 240px;
    height: 172px;
    border: none;

    &:hover {
      background: rgba(255, 183, 0, 0.08);
    }
  }
}

.upload-title {
  font-size: 14px;
  color: #202020;
  line-height: 14px;
  margin-top: 32px;
  margin-bottom: 20px;
}

.upload-progress {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  margin-bottom: 16px;
  color: rgba(32, 32, 32, 0.4);
}

// ::v-deep .el-dialog {
//   border-radius: 8px;
// }

::v-deep .el-dialog__header {
  padding: 32px 24px 32px;
}

::v-deep .el-dialog__body {
  padding: 0 24px 32px;
}

.upload-text {
  color: #000000;
  line-height: 12px;
  font-size: 12px;
  margin-top: 24px;
  margin-bottom: 32px;

  span {
    color: #ffb700;
  }
}

::v-deep .el-dialog {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-top: 0 !important;
  overflow: hidden;
  border-radius: 8px;
}

::v-deep .el-dialog__headerbtn {
  top: 34px !important;
}

::v-deep .el-dialog__close {
  font-size: 20px;
  color: #202020 !important;
}

::v-deep .el-dialog__title {
  font-size: 16px !important;
}
</style>
