<template>
  <div>
    <el-dialog :visible.sync="searchDialogVisible" :close-on-click-modal="false" :close-on-press-escape="false"
      :modal-append-to-body="false" width="800px">
      <div class="search-content">
        <div class="search-text" v-if="isSearching">正在搜索中...</div>
        <div v-else id="search-scroll-box">
          <div class="search-text" v-if="keyword === '' && cardList.length === 0">输入关键词开始搜索!</div>
          <div class="mg-t-20" v-else-if="cardList.length > 0">
            <div class="mg-b-10" v-if="keyword !== '' && !isSearching">共筛选出 {{ cardList.length }} 张卡牌</div>
            <el-row :gutter="12">
              <el-col :span="8" v-for="(card, index) in cardList" :key="card.cardId">
                <div class="mg-b-12 bg-f bdr-10 h-210 pd-10 cursor-pointer shadow-2 position-relative"
                  style="overflow-y: hidden;" @click="onCardClick(index, card)">
                  <div v-if="card.fld1 !== ''" class="text-ellipsis-2 fs-16 c-0 text-strong mg-b-8" v-html="card.fld1">
                  </div>
                  <div class="c-9 fs-14" :class="card.image === '' ? 'text-ellipsis-6' : 'text-ellipsis-1'"
                    v-html="card.fld2"></div>
                  <img v-if="card.image" class="bdr-6 w-fill mg-t-8 mg-b-8 h-80" style="object-fit: cover"
                    :src="card.image" onerror="onImageLoadErr(this)" />
                  <div class="justify-content-space-between fs-12 c-9 position-absolute b-0 l-0 r-0 pd-10">
                    <div>
                      <div class="align-items-center" v-if="holdMap[card.status]">
                        <svg-icon :iconClass="holdMap[card.status].icon"></svg-icon>
                        <div class="card-type-text">{{ holdMap[card.status].name }}</div>
                      </div>
                    </div>
                    <div class="text-ellipsis-1" style="max-width: 174px">{{ card.chapterName }}</div>
                  </div>
                </div>
              </el-col>
            </el-row>
          </div>
          <div class="search-text" v-else-if="keyword !== '' && cardList.length === 0">暂无相关内容的卡牌!</div>
        </div>
      </div>
      <span slot="title" class="el-dialog__title">
        <img src="../../assets/images/icons_action_light_search_24pt@2x.png" class="search-img" />
        <div class="my-select">
          <el-cascader :options="ChapterList" :props="Props" v-model="chapterId" :show-all-levels="false"
            @change="handleSelect" clearable class="w-200" placeholder="选择卡牌盒">
          </el-cascader>
        </div>
        <div class="myInput" id="link-card-input">
          <el-input ref="searCard" v-model="keyword" placeholder="搜索卡片" @input="onInput"></el-input>
        </div>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import request from '../../libs/api.request'
import { debounce } from '../../libs/analyse'
import { mapGetters } from 'vuex'
import dayjs from 'dayjs'

export default {

  components: {},
  data() {
    return {
      searchDialogVisible: false,
      keyword: '',
      cardList: [],
      ChapterList: [],
      selectChapterList: [],
      chapterId: 0, // 选择的卡牌盒id
      isSearching: false,
      page: 1,
      size: 400,
      Props: {
        label: 'name',
        value: 'chapterId',
        children: 'childList',
        checkStrictly: true
      },
      isShowEditorCard: true,
      listScrollHeight: '',
      listClientHeight: 552,
      listScrollTop: '',
      hasMoreData: false,
      isLoadData: false,
      batchSelectedCard: [],
      holdMap: {
        1: { icon: 'new_card', id: 1, name: '新卡' },
        2: { icon: 'handle', id: 2, name: '已掌握' },
        3: { icon: 'studying', id: 3, name: '记忆中' },
        4: { icon: 'no_review', id: 5, name: '不复习' }
      },
    }
  },
  computed: {
    ...mapGetters(['isVip', 'vipLimitData']),
    modifyTime() {
      return (time) => {
        return dayjs(time).format('MM.DD HH:mm')
      }
    },
  },
  methods: {
    open() {
      if (this.$store.state.enableChapterList != null) {
        this.ChapterList = this.modifyChapterList(Array.from(this.$store.state.enableChapterList))
      }
      if (!this.searchDialogVisible) {
        this.searchDialogVisible = true;
        if (JSON.parse(sessionStorage.getItem('linkCardSearchKeyWord'))) {
          this.keyword = JSON.parse(sessionStorage.getItem('linkCardSearchKeyWord'))
        }
        this.getCardList();
        setTimeout(() => {
          document.getElementById('link-card-input').getElementsByTagName('input')[0].focus();
        }, 500)
      }
    },
    onCardClick(index, card) {
      this.searchDialogVisible = false;
      this.cardList = [];
      this.keyword = '';
      if (!this.isVip && this.vipLimitData && this.vipLimitData.cardLink.limit <= this.vipLimitData.cardLink.use) {
        this.$store.commit('changeShowIntroduceVip', true)
        return
      }
      this.$emit('onSelectedCard', { card })
    },
    modifyChapterList(data) {
      for (let i = 0; i < data.length; i++) {
        if (data[i].childList == null || data[i].childList.length < 1) {
          data[i].childList = undefined
        } else {
          this.modifyChapterList(data[i].childList)
        }
      }
      return data
    },
    handleSelect(value) {
      if (value.length !== 0) {
        this.chapterId = value[value.length - 1]
      } else {
        this.chapterId = 0
      }
      this.getCardList()
    },
    onInput: debounce(function () {
      if (this.keyword.replace(/(^\s*)|(\s*$)/g, "") === '') {
        return
      }
      this.getCardList()
    }, 200),
    getCardList() {
      this.isSearching = true
      request.request({
        url: '/card/all',
        params: {
          chapterId: this.chapterId || 0,
          keyword: this.keyword,
          sort: 'ncrt',
          size: this.keyword === '' ? 100 : this.size,
        }
      }).then(res => {
        this.cardList = res.list
        this.isSearching = false
        this.hasMoreData = res.list.length < this.size
        sessionStorage.setItem('linkCardSearchKeyWord', JSON.stringify(this.keyword))
      })
    },
  },
  mounted() {
    window.addEventListener(
      "message",
      this.postMessageListener,
      true
    );
  },
  beforeDestroy() {
    window.removeEventListener(
      "message",
      this.postMessageListener,
      true
    );
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/css/variable.scss";
@import "@/assets/css/mixin.scss";

.search-content {
  /*position: relative;*/
  height: calc(586px - 64px);

  &::-webkit-scrollbar {
    width: 0px;
  }

  overflow-y: auto;

  .search-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: $base-color;
  }
}


::v-deep .el-dialog__body {
  box-sizing: border-box;
  background-color: #fafafa;
  padding-top: 0;
  padding-bottom: 0;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

::v-deep .el-dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 12px;
  margin-top: 0 !important;
  height: 586px;

  &::-webkit-scrollbar {
    width: 0px;
  }
}

::v-deep .el-cascader-menu {
  max-width: 250px;
}

.myInput {
  width: 100%;

  ::v-deep .el-input__inner {
    padding-left: 16px;
    padding-right: 38px;
    height: 100%;
    border: none;
    font-size: 16px;
    color: #202020;

    &::placeholder {
      font-size: 14px;
      color: rgba(32, 32, 32, 0.4);
    }

    &::-webkit-input-placeholder {
      /* WebKit browsers 适配谷歌 */
      font-size: 14px;
      color: rgba(32, 32, 32, 0.4);
    }
  }
}

.my-select {
  ::v-deep .el-input__inner {
    text-align: center;
    border-radius: 8px;
    height: 32px;
    line-height: 32px;
    background: #f7f7f7;
  }

  ::v-deep .el-input__icon {
    height: 32px;
    line-height: 32px;
  }
}

::v-deep .el-cascader {
  width: 200px;
}

::v-deep .el-dialog__header {
  padding: 16px 24px 16px;
  height: 64px;
  border-bottom: 1px solid rgba(32, 32, 32, 0.06);
}

::v-deep .el-dialog__headerbtn .el-dialog__close {
  font-size: 22px;
  top: 10px;
}

::v-deep .el-dialog__headerbtn {
  right: 24px;
  top: 21px;
}

::v-deep .el-dialog__title {
  display: flex;
  align-items: center;

  .search-img {
    width: 20px;
    height: 20px;
    margin-right: 16px;
  }

}

::v-deep .el-select {
  .el-input__inner {
    height: 32px;
    line-height: 32px;
    border-radius: 8px;
    font-size: 14px;
    color: #202020;
    border: none;
    background-color: #f7f7f7;
  }

  .el-input__icon {
    line-height: 32px;
  }
}

::v-deep .el-scrollbar {
  .el-scrollbar__bar {
    display: none;
    width: 0 !important;
  }
}
</style>
<style>
.myselect {
  max-width: 400px !important;
  min-width: max-content !important;
  z-index: 9999 !important;
}

.el-cascader-menu {
  max-width: 250px;
}

/* .myselect .el-select-dropdown__item {
  display: flex;
  width: 100% !important;
  justify-content: space-between;
  align-items: center;
  height: 48px;
  line-height: 48px;
  padding: 0 12px 0 16px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.myselect .el-scrollbar__bar {
  width: 0 !important;
} */
</style>
