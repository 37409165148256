<template>
  <el-dialog title="选择模板" :visible.sync="isShowSelectModel" width="552px" :append-to-body="appendBody">
    <div class="official-model">
      <div class="official-model-title">官方模版</div>
      <div class="model-container">
        <div class="model-item" v-for="(model, index) in officialList" :key="model.modelId"
          :style="{marginRight:(index+1)% 4 ===0? '0px':'24px'}">
          <img class="model-img" :class="currentIndex === index && model.enable === 1 ? 'active' : ''"
            @click="modelSelect(model, index)" :src="model.image" />
          <div class="model-title">{{ model.name }}</div>
          <div class="model-desc">{{ model.desc }}</div>
        </div>
      </div>
    </div>
    <div class="other-model">
      <div class="other-model-title">
        <div>其他模版</div>
        <div class="display-flex">
          <div style="color:#FFB700;font-size:12px" class="cursor-pointer mg-r-16" @click="onCreateModel">创建</div>
          <div style="color:#FFB700;font-size:12px" class="cursor-pointer" @click="openImportData">导入</div>
        </div>
      </div>
      <div class="other-model-container">
        <div class="other-model-item" v-for="(model, index) in otherList" @click="hanleModelSelect(model)">
          <div class="model-menu-box">
            <div class="model-menu">
              <img :src="menu.icon" alt="" class="w-18 h-18" v-for="menu in menuList" :key="menu.id"
                @click.stop="menuClick(menu.id,index,model)">
            </div>
          </div>
          <div class="model-name">{{ model.name }}</div>
        </div>
      </div>
    </div>
    <ModelEditor v-if="modelVisible" :modelVisible.sync="modelVisible" @updateModelVisible="updateModelVisible"
      :model="model">
    </ModelEditor>
  </el-dialog>
</template>

<script>
import request from '../libs/api.request'
import { mapGetters } from "vuex"
import { showMessage } from '../libs/message'
import ModelEditor from './common/model/ModelEditor.vue'
export default {
  props: {
    showSelectModel: {
      tyep: Boolean,
      default: false
    },
    // 在search弹框里面，选择模版会显示不了，需要给他false
    appendBody:{
      type:Boolean,
      default: true
    }
  },
  components: {
    ModelEditor
  },
  data() {
    return {
      currentIndex: 0,
      menuList: [
        // {
        //   icon: require('../assets/images/keepTop_model.png'),
        //   id: 1
        // },
        {
          icon: require('../assets/images/edit_model.png'),
          id: 2
        }, 
        // {
        //   icon: require('../assets/images/copy_model.png'),
        //   id: 3
        // }, 
        {
          icon: require('../assets/images/delete_model.png'),
          id: 4
        },
      ],
      officialList: [],
      otherList: [],
      modelVisible: false,
      model: {}
    }
  },
  methods: {
    menuClick(id, index, model) {
      switch (id) {
        case 1:
          break;
        case 2:
          if(model.creatorId !== model.userId ){
            return showMessage({message:'只允许编辑自己的模版', type:'error'})
          }
          request.request({
            url: '/model/detail',
            params: {
              modelId: model.modelId
            }
          }).then(res => {
            this.model = res
            this.modelVisible = true
          })
          break
        case 3:
          break;
        case 4:
          request.request({
            url: '/model/cardNum',
            params: {
              modelId: model.modelId
            }
          }).then(res => {
            let desc = ''
            if (res.cardNum > 0) {
              desc = res.cardNum + '张卡片正在使用该模板，请先删除对应卡片，再删除模板'
            } else {
              desc = '确定删除该模版？'
            }
            this.$confirm(desc, '删除模版', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              beforeClose: (action, instance, done) => {
                if (action === 'confirm') {
                  if (res.cardNum === 0) {
                    request.request({
                      url: '/model/delete',
                      method: 'post',
                      data: { modelId: model.modelId, }
                    }).then((res) => {
                      showMessage({ message: '删除成功！', type: 'success' })
                      this.otherList.splice(index, 1)
                      this.$store.commit('changeModelList', model.modelId)
                      done();
                    }).catch((e) => {
                      showMessage({ message: e, type: 'error' })
                    })
                  } else {
                    done();
                  }
                } else {
                  done();
                }
              }
            })
          })
          break
      }
    },
    onCreateModel() {
      this.model = {}
      this.modelVisible = true
    },
    openImportData() {
      // this.$refs.importAnki.open()
      console.log('导入');
      this.$emit('openImportAnki')
    },
    open() {
      this.isShowSelectModel = true;
    },
    modelSelect(model, index) {
      if (model.enable !== 0) {
        this.currentIndex = index
        this.isShowSelectModel = false;
        this.$emit('selectModel', model)
      }
    },
    hanleModelSelect(model) {
      this.isShowSelectModel = false;

      this.currentIndex = ''
      this.$emit('selectModel', model)
    },
    updateModelVisible(v) {
      this.modelVisible = v
    }
  },
  computed: {
    ...mapGetters(['getModelList']),
    isShowSelectModel: {
      get() {
        return this.showSelectModel
      },
      set(v) {
        this.$emit('updateShowSelectModel', v)
      }
    }
  },
  watch: {
    getModelList(v) {
      this.otherList = v.filter(i => {
        return !i.recommend
      })
    }
  },
  mounted() {
    this.officialList = this.getModelList.filter(i => {
      return i.recommend
    })
    this.otherList = this.getModelList.filter(i => {
      return !i.recommend
    })
  },
}
</script>

<style lang="scss" scoped>
@import "@/assets/css/mixin.scss";

.official-model {
  .official-model-title {
    margin-bottom: 20px;
  }

  .model-container {
    display: flex;
    flex-wrap: wrap;

    .model-item {
      width: 108px;
      margin-bottom: 10px;

      .model-img {
        width: 100%;
        height: 144px;
        border-radius: 8px;
        border: 2px solid transparent;

        &.active {
          border-color: #ffb700;
        }

        &:hover {
          cursor: pointer;
          background: rgba(123, 123, 128, 0.08);
          border-color: #ffb700;
        }
      }

      .model-title {
        font-weight: 600;
        text-align: center;
        margin: 16px 0 12px;
      }

      .model-desc {
        font-size: 12px;
        color: rgba(32, 32, 32, 0.4);
        text-align: center;
      }
    }
  }
}

.other-model {
  margin-top: 8px;

  .other-model-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }

  .other-model-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .other-model-item {
      width: 240px;
      height: 40px;
      text-align: center;
      line-height: 40px;
      border-radius: 8px;
      border: 1px solid rgba(32, 32, 32, 0.16);
      margin-bottom: 20px;
      position: relative;

      .model-name {
        @include clamp(1);
      }

      .model-menu-box {
        position: absolute;
        top: -34px;
        left: 50%;
        width: 80%;
        transform: translateX(-50%);
        display: none;
        align-items: center;
        justify-content: space-around;
        padding-bottom: 10px;

        .model-menu {
          width: 80%;
          height: 30px;
          border: 1px solid #ccc;
          background-color: #fff;
          border-radius: 6px;
          display: flex;
          justify-content: space-around;
          align-items: center;
        }
      }

      &:hover {
        cursor: pointer;
        background: rgba(123, 123, 128, 0.08);

        .model-menu-box {
          display: flex;
        }
      }
    }
  }
}

::v-deep .el-dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 9997 !important;
  transform: translate(-50%, -50%);
  margin-top: 0 !important;
  border-radius: 12px;
}

::v-deep .el-dialog__body {
  padding: 6px 24px 0;
  height: 528px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 0;
  }
}
</style>
