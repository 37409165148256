<template>
  <el-dialog width="552px" :visible.sync="showDialog"
             :close-on-press-escape="false"
             :close-on-click-modal="false"
             :before-close="beforeDialogClose"
  >
    <div slot="title">
      <span class="cursor-pointer" @click="onBackClick">返回上一步</span>
    </div>
    <div class="upload-container">
      <el-upload
          drag
          accept=".apkg,.colpkg,.1g"
          :on-progress="onUploadProgress"
          :on-success="onUploadSuccess"
          :before-upload="beforeUpload"
          :on-error="onUploadError"
          :show-file-list="false"
          :headers="uploadHeaders"
          :action="apiBaseUrl + '/study/uploadFile'"
          ref="upload"
          :disabled="uploading"
      >
        <img
            style="width: 76px;height: 76px;margin-top: 30px;"
            src="../assets/images/images_general_file_PC_light_anki@2x.png"
        />
        <div class="el-upload__text upload-text">
          将.apkg(Anki)文件拖动到此或点击上传
        </div>
      </el-upload>
    </div>
    <div class="upload-title" v-if="step>0">
      <span v-if="step == 1">上传文件中</span>
      <span v-else-if="step == 2">上传完成，数据导入中</span>
    </div>
    <div class="upload-progress" v-if="step>0">
      <div class="progress">
        <span>{{progress}}%</span>
      </div>
      <div class="h-50 align-items-center">
        <svg-icon iconClass="importing_close" class="w-30 h-30 cursor-pointer" v-if="step == 1" @click="cancelUpload"></svg-icon>
      </div>
    </div>
    <el-progress v-if="step > 0" :color="'#FFD60A'" :show-text="false" :stroke-width="6"
                 :percentage="progress"></el-progress>
  </el-dialog>
</template>

<script>
import request from '../libs/api.request'
import {hideScrollBar, showScrollBar} from "../libs/util";
import {showMessage} from '../libs/message'

export default {
  props: {},
  data() {
    return {
      showDialog: false,
      uploadHeaders: {},
      apiBaseUrl: request.baseUrl,
      progress: 0,
      step: 0, //步骤：  0无，1上传中，2导入中
      checkCode: '',
      uploadFile: null,
      uploading: false,
    }
  },
  methods: {
    onBackClick() {
      if (!this.uploading) {
        this.close();
        this.$emit('onBackClick');
      }
    },
    open() {
      hideScrollBar();
      this.showDialog = true;
    },
    close() {
      showScrollBar();
      this.showDialog = false;
    },
    beforeDialogClose(done) {
      if (this.step == 1 && this.uploadFile) {
        this.$refs.upload.abort(this.uploadFile);
        this.uploadFile = null;
      }
      showScrollBar();
      done();
    },
    cancelUpload() {
      if (this.step == 1 && this.uploadFile) {
        this.$refs.upload.abort(this.uploadFile);
        this.uploadFile = null;
        this.step = 0;
        this.progress = 0;
        this.uploading = false
      }
    },
    // 上传之前
    beforeUpload(file) {
      this.step = 1;
      this.uploadFile = file;
      console.log('上传前', file);
    },
    // 上传中
    onUploadProgress(event, file) {
      this.uploading = true
      this.progress = parseInt(event.percent)
    },
    // 上传成功
    onUploadSuccess(response, file) {
      this.progress = 0;
      this.uploading = false
      if (response.code === 0) {
        this.checkCode = response.data.checkCode;
        this.starImportProgress();
      }
      if (response.code === 1) {
        this.step = 0;
        showMessage({message: response.message, type: 'warning'})
      }
    },
    // 上传失败
    onUploadError() {
      this.step = 0;
      this.progress = 0;
      this.uploading = false
      showMessage({message: '上传失败，请重试或检查网络', type: 'error'})
    },
    starImportProgress() {
      this.step = 2;
      this.progress = 0;
      this.checkImportProgress();
    },
    checkImportProgress() {
      request.request({
        url: '/study/checkImport',
        params: {checkCode: this.checkCode}
      }).then((data) => {
        if (data.status == 1) {
          this.progress = data.progress;
          this.checkImportProgress();
        } else if (data.status == 2) {
          console.log(data);
          this.progress = 100;
          this.$store.commit('setModelList', data.modelList);
          this.$store.dispatch('modifyAllChapterList')
          this.$store.dispatch('modifyTotalChapterData')
          this.$store.dispatch('modifyChapterList')
          setTimeout(() => {
            if (data.chapterId > 0) {
              this.$confirm('导入完成！', '提示', {
                confirmButtonText: '前往学习',
                cancelButtonText: '继续导入',
                type: 'success',
                beforeClose: (action, instance, done) => {
                  this.step = 0;
                  this.progress = 0;
                  this.uploading = false
                  done();
                  if (action === 'confirm') {
                    if (this.$route.path.indexOf('/home/studyPlatform/chapter/') !== -1) {
                      location.href = '/home/studyPlatform/chapter/' + data.chapterId;
                    } else {
                      this.$router.push({path: '/home/studyPlatform/chapter/' + data.chapterId});
                    }
                  }
                }
              });
            } else {
              this.$alert('导入完成', {
                callback: () => {
                  location.reload();
                }
              });
            }
          }, 800);//给个1秒钟延时进度条效果
        } else if (data.status == -1) {//导入错误
          this.step = 0;
          this.progress = 0;
          this.uploading = false
          showMessage({message: data.errMsg, type: 'warning'})
        }
      }).catch(() => {
        this.uploading = false
        setTimeout(() => {
          this.checkImportProgress();
        }, 2000)
      })
    }
  },
  created() {
    this.uploadHeaders = {
      authorization: this.$cookies.get('authToken')
    }
  }
}
</script>

<style lang="scss" scoped>

.upload-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  .wechat-book {
    border-radius: 12px;
    width: 240px;
    height: 172px;
    background: #f2f2f2;
    padding: 0 82px;

    &:hover {
      cursor: pointer;
    }

    .wechat-book-text {
      .text {
        font-size: 12px;
        color: rgba(32, 32, 32, 0.4);
      }
    }
  }

  ::v-deep .el-upload {
    border: 1px dashed transparent;
    box-sizing: border-box;
    border-radius: 12px;
    background: linear-gradient(white, white) padding-box,
    repeating-linear-gradient(
            -45deg,
            transparent 0,
            transparent 0.4em,
            #ccc 0,
            #ccc 0.85em
    );

    &:hover {
      border: 1px dashed transparent;
      background: linear-gradient(white, white) padding-box,
      repeating-linear-gradient(
              -45deg,
              white 0,
              white 0.4em,
              #ffb700 0,
              #ffb700 0.85em
      );
    }
  }

  ::v-deep .el-upload-dragger {
    border-radius: 12px;
    width: 240px;
    height: 172px;
    border: none;

    &:hover {
      background: rgba(255, 183, 0, 0.08);
    }
  }
}

.upload-title {
  font-size: 14px;
  color: #202020;
  line-height: 14px;
  margin-top: 32px;
  margin-bottom: 20px;
}

.upload-progress {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  margin-bottom: 16px;
  color: rgba(32, 32, 32, 0.4);
}

// ::v-deep .el-dialog {
//   border-radius: 8px;
// }

::v-deep .el-dialog__header {
  padding: 32px 24px 32px;
}

::v-deep .el-dialog__body {
  padding: 0 24px 32px;
}

.upload-text {
  color: #000000;
  line-height: 12px;
  font-size: 12px;
  margin-top: 24px;
  margin-bottom: 32px;

  span {
    color: #ffb700;
  }
}

::v-deep .el-dialog {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-top: 0 !important;
  overflow: hidden;
  border-radius: 8px;
}

::v-deep .el-dialog__headerbtn {
  top: 34px !important;
}

::v-deep .el-dialog__close {
  font-size: 20px;
  color: #202020 !important;
}

::v-deep .el-dialog__title {
  font-size: 16px !important;
}
</style>
