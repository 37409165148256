import { Message } from "element-ui";
export const copyData = (text, message) => {
  navigator.clipboard.writeText(text).then(res => {
    Message.success({
      message: message || '卡牌盒链接已复制'
    })
  }).catch(e => {
    // mac 两种方法都使用不了
    setTimeout(() => {
      var cInput = document.createElement("input");
      cInput.value = text;
      document.body.appendChild(cInput);
      cInput.select(); // 选取文本框内容
      // 执行浏览器复制命令
      // 复制命令会将当前选中的内容复制到剪切板中（这里就是创建的input标签）
      // Input要在正常的编辑状态下原生复制方法才会生效
      document.execCommand("copy");
      Message.success({
        message: message || '卡牌盒链接已复制'
      })
      document.body.removeChild(cInput);
    }, 100);
  })
}