<template>
  <div class="position-fixed t-0 b-0 l-0 r-0 bg-f9f pd-b-100 pd-t-15"
       style="z-index: 999999;overflow-y: scroll;">
    <div class="w-fill" style="display: table;height: 100%">
      <div style="display: table-cell;vertical-align: middle">
        <div class="justify-content-center">
          <div class="image-cover-container bdr-5 shadow-1">
            <canvas id="image-cover-canvas" class="bdr-5" :width="canvasW" :height="canvasH"></canvas>
          </div>
        </div>

      </div>
    </div>
    <div class="position-fixed b-0 h-55 w-fill fs-12 bg-f shadow-1 justify-content-center">
      <div class="align-items-center justify-content-space-between  pd-t-10 pd-b-10 pd-l-30 pd-r-30 flex-wrap"
           style="width: 1050px">
        <div class=" cursor-pointer" @click="cancelImageEdit">
          <img src="../../assets/images/icons_action_light_close_24pt@2x.png" class="w-24">
        </div>
        <div class="align-items-center">
          <div class="align-items-center ">
            <div class="h-30 w-30 pd-4 bdr-circular mg-l-10 mg-r-10 bg-f" v-for="color,index in colors"
                 v-if="index < colors.length - 3 || drawType === 'rect'"
                 :class="selectColor === color?'':''"
                 :style="selectColor === color?'box-shadow:0 2px 6px 0 rgb(0 0 0 / 30%);':''">
              <div class="bdr-20 w-fill h-fill  cursor-pointer" @click="onSelectColor(color)"
                   :style="'background-color: '+color"></div>
            </div>
            <template v-if="drawType == 'pen'">
              <div class="mg-l-10 mg-r-10 pd-4 bg-f h-30 w-30 cursor-pointer bdr-circular "
                   :style="penSize == 2?'box-shadow:0 2px 6px 0 rgb(0 0 0 / 30%);':''" @click="changePenSize(2)">
                <div class="w-fill h-fill  bdr-circular justify-content-center align-items-center">
                  <div class="h-2 w-20 bg-2 bdr-1"></div>
                </div>
              </div>
              <div class="mg-l-10 mg-r-10 pd-4 bg-f h-30 w-30 cursor-pointer bdr-circular"
                   :style="penSize == 16?'box-shadow:0 2px 6px 0 rgb(0 0 0 / 30%);':''" @click="changePenSize(16)">
                <div class="w-fill h-fill  bdr-circular justify-content-center align-items-center">
                  <div class="h-4 w-20 bg-2 bdr-2"></div>
                </div>
              </div>
              <div class="mg-l-10 mg-r-10 pd-4 bg-f h-30 w-30 cursor-pointer bdr-circular"
                   :style="penSize == 30?'box-shadow:0 2px 6px 0 rgb(0 0 0 / 30%);':''" @click="changePenSize(30)">
                <div class="w-fill h-fill  bdr-circular justify-content-center align-items-center">
                  <div class="h-8 w-20 bg-2 bdr-2"></div>
                </div>
              </div>
            </template>
          </div>
          <div class="w-1 bg-e8e h-15 mg-l-10 mg-r-10"></div>
          <div class="align-items-center">
            <!--<div class="pd-10 cursor-pointer" :class="drawType == ''?'c-app-yellow':''" @click="changeDrawType('')">-->
            <!--移动-->
            <!--</div>-->
            <div class="mg-l-10 mg-r-10 cursor-pointer" :class="drawType == 'rect'?'c-app-yellow':''"
                 @click="changeDrawType('rect')">
              <div class="pd-1 bdr-4" style="border: 1.5px #222222 solid"
                   :class="drawType == 'rect'?'bdc-app-yellow':''">
                <div class="w-18 h-14 bdr-2" :style="'background-color: '+selectColor"></div>
              </div>
            </div>
            <div class="mg-l-15 mg-r-15 cursor-pointer" :class="drawType == 'pen'?'c-app-yellow':''"
                 @click="changeDrawType('pen')">
              <img v-if="drawType === 'pen'" src="../../assets/images/icons_outline_toggle_light_line_active@2x.png"
                   class="h-22">
              <img v-else src="../../assets/images/icons_outline_toggle_light_line@2x.png" class="h-22">
            </div>
            <div class="mg-l-15 mg-r-15 cursor-pointer" @click="clearCanvas">
              <img src="../../assets/images/icons_outline_toggle_light_brush@2x.png" class="h-22">
            </div>
            <div class="mg-l-15 mg-r-15 cursor-pointer" @click="undo">
              <img src="../../assets/images/icons_action_dark_undo_24pt@2x.png" class="h-22">
            </div>
          </div>
        </div>
        <div class="pd-t-5 pd-b-5 cursor-pointer bg-app-yellow c-f bdr-10 w-80 text-align-center fs-14" @click="finish">
          完成
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ImageCoverEditor",
  props: {
    doImageCoverSrc: {
      type: String,
      default: ''
    },
    doImageCoverSvg: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      canvasW: 0,
      canvasH: 0,
      canvas: null,
      isDrawing: false,
      mouseFrom: {}, // 鼠标绘制起点
      mouseTo: {}, // 鼠标绘制重点
      drawingObject: null,
      drawType: 'rect',
      colors: ['#2B2B2B', '#E65D57', '#F6C544', '#58BE6C', '#4EACF7', '#6566E7', '#AF52DE'],
      selectColor: '#F6C544',
      penSize: 2,
      stateArr: [],
      isRedoing: false,
      recordTimer: null,
      renderSetTimeout: null,

    }
  },
  methods: {
    onSelectColor(color) {
      if (color !== this.selectColor) {
        this.selectColor = color;
        this.canvas.freeDrawingBrush.color = this.selectColor;
      }
    },
    changePenSize(size) {
      if (size !== this.penSize) {
        this.penSize = size;
        this.canvas.freeDrawingBrush.width = parseInt(this.penSize);
      }
    },
    changeDrawType(type) {
      this.drawType = type;
      if (this.drawType === 'pen') {
        // 设置绘画模式画笔类型为 铅笔类型
        this.canvas.freeDrawingBrush = new fabric.PencilBrush(this.canvas);
        // 设置画布模式为绘画模式
        this.canvas.isDrawingMode = true;
        // 设置绘画模式 画笔颜色与画笔线条大小
        this.canvas.freeDrawingBrush.color = this.selectColor;
        this.canvas.freeDrawingBrush.width = parseInt(this.penSize);
      } else {
        this.canvas.isDrawingMode = false;
      }
    },
    cancelImageEdit() {
      this.$emit('cancelImageEdit')
    },
    finish() {
      let svg = this.canvas.toSVG();
      svg = svg.substr(svg.indexOf('<svg'));
      svg = svg.replace(new RegExp(/<desc(?:(?!<\/desc>).|\n)*?<\/desc>/, 'gm'), '<desc data-image-cover=\'1\'></desc>');
      svg = svg.replace(new RegExp(/<defs(?:(?!<\/defs>).|\n)*?<\/defs>/, 'gm'), '');
      svg = svg.replace(new RegExp("\n", 'gm'), '');
      let svgEl = document.createElement('div');
      svgEl.innerHTML = svg;
      this.canvas = null;
      if (svgEl.getElementsByTagName('svg')[0].innerHTML.length < 10) {
        svg = '';
      }
      this.$emit('finishImageCover', svg)
    },
    undo() {
      this.isRedoing = true
      console.log(this.stateArr);
      if (this.stateArr.length > 1) {
        let json = this.stateArr.pop();
        console.log(json);
        this.canvas.loadFromJSON(this.stateArr[this.stateArr.length - 1]);
      }
    },
    clearCanvas() {
      let children = this.canvas.getObjects()
      if (children.length > 0) {
        this.canvas.remove(...children)
      }
    },
    initRect() {
      // 计算矩形长宽
      let left = this.getTransformedPosX(this.mouseFrom.x);
      let top = this.getTransformedPosY(this.mouseFrom.y);
      let width = (this.mouseTo.x - this.mouseFrom.x) / this.canvas.getZoom();
      let height = (this.mouseTo.y - this.mouseFrom.y) / this.canvas.getZoom();
      if (width < 0 || height < 0) {
        if (this.drawingObject) {
          this.canvas.remove(this.drawingObject);
        }
        return;
      }
      let rectParams = {
        left: left,
        top: top,
        width: width,
        height: height,
        stroke: this.selectColor,
        fill: this.selectColor,
        strokeWidth: 5,
      };
      // 创建矩形 对象
      let canvasObject = new fabric.Rect(rectParams);
      if (this.drawingObject) {
        this.canvas.remove(this.drawingObject);
      }
      // 将绘制对象添加到 canvas中
      this.canvas.add(canvasObject);
      // 保存当前绘制的图形
      this.drawingObject = canvasObject;
    },
    getTransformedPosX(x) {
      let zoom = Number(this.canvas.getZoom())
      return (x - this.canvas.viewportTransform[4]) / zoom;
    },
    getTransformedPosY(y) {
      let zoom = Number(this.canvas.getZoom())
      return (y - this.canvas.viewportTransform[5]) / zoom;
    },
  },
  mounted() {
    var img = document.createElement('img');
    img.src = this.doImageCoverSrc;
    img.onload = () => {
      let rate = img.width / img.height;
      let w1 = Math.min(img.width, window.innerWidth, 1000);
      let h2 = w1 / rate;
      this.canvasW = w1;
      this.canvasH = h2;
      this.stateArr = [];
      setTimeout(() => {
        var deleteIcon = "data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3E%3Csvg version='1.1' id='Ebene_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='595.275px' height='595.275px' viewBox='200 215 230 470' xml:space='preserve'%3E%3Ccircle style='fill:%23F44336;' cx='299.76' cy='439.067' r='218.516'/%3E%3Cg%3E%3Crect x='267.162' y='307.978' transform='matrix(0.7071 -0.7071 0.7071 0.7071 -222.6202 340.6915)' style='fill:white;' width='65.545' height='262.18'/%3E%3Crect x='266.988' y='308.153' transform='matrix(0.7071 0.7071 -0.7071 0.7071 398.3889 -83.3116)' style='fill:white;' width='65.544' height='262.179'/%3E%3C/g%3E%3C/svg%3E";
        var deleteImage = document.createElement('img');
        deleteImage.src = deleteIcon;

        function deleteObject(eventData, transform) {
          var target = transform.target;
          var canvas = target.canvas;
          canvas.remove(target);
          canvas.requestRenderAll();
        }

        function renderIcon(ctx, left, top, styleOverride, fabricObject) {
          var size = this.cornerSize;
          ctx.save();
          ctx.translate(left, top);
          ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
          ctx.drawImage(deleteImage, -size / 2, -size / 2, size, size);
          ctx.restore();
        }

        fabric.Object.prototype.controls.deleteControl = new fabric.Control({
          x: 0.5,
          y: -0.5,
          offsetY: 0,
          cursorStyle: 'pointer',
          mouseUpHandler: deleteObject,
          render: renderIcon,
          cornerSize: 24
        });
        fabric.Object.prototype.cornerColor = '#FFB100';
        fabric.Object.prototype.borderColor = '#FFB100';
        fabric.Object.prototype.cornerStyle = 'circle';
        fabric.Object.prototype.transparentCorners = false;
        this.canvas = new fabric.Canvas('image-cover-canvas');
        let match = this.doImageCoverSvg.match(/width=\"(.*?)\"/);
        if (match && match[1]) {
          this.canvas.setZoom(w1 / parseInt(match[1]));
        }
        this.canvas.selection = false;
        if (this.doImageCoverSvg) {
          let svg = this.doImageCoverSvg.replace(new RegExp('&nbsp;', 'gm'), '');
          fabric.loadSVGFromString(svg, (objects) => {
            console.log('????????', svg, objects);
            // 把组合设置为选中
            objects.forEach((obj) => {
              this.canvas.add(obj)
            })
            // 重新渲染
            this.canvas.renderAll()
          })
        } else {
          // this.drawType = 'rect';
        }
        this.stateArr.push(JSON.stringify(this.canvas));
        this.canvas.on("mouse:down", (options) => {
          if (this.canvas.getActiveObject() || this.drawType === '' || this.drawType === 'pen')
            return;
          console.log("mouse.down");
          console.log(options);
          if (options.e.clientX === undefined) {
            this.mouseFrom.x = options.e.changedTouches[0].clientX - this.canvas._offset.left;
            this.mouseFrom.y = options.e.changedTouches[0].clientY - this.canvas._offset.top;
          } else {
            this.mouseFrom.x = options.e.clientX - this.canvas._offset.left;
            this.mouseFrom.y = options.e.clientY - this.canvas._offset.top;
          }
          let drawObjects = this.canvas.getObjects();
          if (drawObjects.length > 0) {
            drawObjects.map((item) => {
              // console.log(item);
            });
          }
          this.isDrawing = true;
        });

        this.canvas.on("mouse:move", (options) => {
          // console.log("mouse.move", options);
          if (this.drawType === '' || this.drawType === 'pen')
            return;
          if (options.e.clientX === undefined) {
            this.mouseTo.x = options.e.changedTouches[0].clientX - this.canvas._offset.left
            this.mouseTo.y = options.e.changedTouches[0].clientY - this.canvas._offset.top
          } else {
            this.mouseTo.x = options.e.clientX - this.canvas._offset.left
            this.mouseTo.y = options.e.clientY - this.canvas._offset.top
          }
          if (this.isDrawing) {
            this.initRect();
          }
        });

        this.canvas.on("mouse:up", (options) => {
          if (this.drawType === '' || this.drawType === 'pen')
            return;
          this.isDrawing = false;
          this.drawingObject = null;
          setTimeout(() => {
            this.stateArr.push(JSON.stringify(this.canvas));
          }, 100)
        });

        this.canvas.on("after:render", () => {
          if (this.isDrawing)
            return;
          if (!this.isRedoing) {
            if (this.renderSetTimeout) {
              clearTimeout(this.renderSetTimeout);
            }
            this.renderSetTimeout = setTimeout(() => {
              this.stateArr.push(JSON.stringify(this.canvas));
              this.renderSetTimeout = null;
            }, 1000)
          } else {
            // 当前正在执行撤销或重做操作，不记录重新绘制的画布
            this.isRedoing = false
          }
        })

        document.getElementById('image-cover-canvas').style.backgroundImage = "url(" + this.doImageCoverSrc + ")";
        document.getElementById('image-cover-canvas').style.backgroundSize = "contain";
      }, 10)
    }
  },
  created() {
    // document.body.style.cssText = 'overflow-y: hidden; height: 100%;';
  },
  destroyed() {
    // document.body.style.cssText = 'height: 100%;';
  }
}
</script>

<style scoped>

#image-cover-canvas {
  background-size: contain;
}

.image-cover-container {
  background-color: white;
}
</style>
