// 卡牌内容渲染
import htmlTpl from "./HtmlTpl";

const renderHtml = function (cardStatus, card, model, nightMode) {
  let html = htmlTpl;
  html = html.replace("{{script}}", "");
  let tmplsIndex = Math.min(
    card.ord ? parseInt(card.ord) : 0,
    model.tmpls.length - 1
  );
  let content = model.tmpls[tmplsIndex][cardStatus];
  if (cardStatus === "afmt") {
    content = content
      .split("{{FrontSide}}")
      .join(model.tmpls[tmplsIndex]["qfmt"]);
    html = html.replace("::class::", "ck-back");
  } else {
    html = html.replace("::class::", "ck-front");
  }
  content = content.replace(new RegExp("{{=<% %>=}}", "gm"), "");
  content = content.replace(new RegExp("<%", "gm"), "{{");
  content = content.replace(new RegExp("%>", "gm"), "}}");
  content = content.replace(new RegExp("{{Deck}}", "gm"), card.chapterName);
  content = content.replace(new RegExp("{{Subdeck}}", "gm"), card.chapterName);
  let hasCloze = content.indexOf("{{cloze") > -1; //  挖空模板
  let needFindType = content.indexOf("{{type:") > -1; //   输入答案模板
  let needFindHint = content.indexOf("{{hint:") > -1; //   点击显示模板
  model.flds.forEach((v, k) => {
    content = content.replace(
      new RegExp("{{" + v.name + "}}", "gm"),
      card.flds[k]
    );
    content = content.replace(
      new RegExp("{{edit:" + v.name + "}}", "gm"),
      card.flds[k]
    );
    content = content.replace(new RegExp("{{furigana:" + v.name + "}}", "gm"), card.flds[k]);
    if (hasCloze) {
      if (v.name !== "Text" && v.name.indexOf("Text") === 0) {
        let txtOrd = parseInt(v.name.replace("Text", ""));
        content = content.replace(
          new RegExp("{{cloze:" + v.name + "}}", "gm"),
          txtOrd === parseInt(card.ord) + 1 ? card.flds[k] : ""
        );
      } else {
        content = content.replace(
          new RegExp("{{cloze:" + v.name + "}}", "gm"),
          card.flds[k]
        );
      }
    }
    content = findHideIfEmpty(content, v.name, card.flds[k]);
    content = findHideIfHas(content, v.name, card.flds[k]);
    if (needFindHint) {
      content = findHint(content, v.name, card.flds[k]);
    }
    if (needFindType) {
      content = findType(cardStatus, content, v.name, card.flds[k]);
    }
  });
  content = content.replace(new RegExp("{{Tags}}", "gm"), card.otags.join(' '));
  content = findHideIfEmpty(content, "Tags", card.otags.join(' '));
  content = findHideIfHas(content, "Tags", card.otags.join(' '));
  content = findMedia(content);
  let arr = content.match(/\{\{c([\w\W]*?)\}\}/gi);
  if (arr) {
    arr.forEach((v, k) => {
      let newArr = v.substr(3, v.length - 5).split("::");
      if (newArr.length === 2) {
        if (cardStatus === "afmt") {
          if (!hasCloze || parseInt(newArr[0]) === parseInt(card.ord) + 1) {
            content = content.replace(v, '<span class="cloze">' + newArr[1] + "</span>");
          } else {
            content = content.replace(v, newArr[1]);
          }
        } else {
          if (!hasCloze || parseInt(newArr[0]) === parseInt(card.ord) + 1) {
            content = content.replace(v, '<span class="cloze">[...]</span>');
          } else {
            content = content.replace(v, newArr[1]);
          }
        }
      }
    });
  }
  let splitContent = html.split("{{content}}");
  html = splitContent[0] + content + splitContent[1];
  let style = model["css"];
  if (card.editable === 0) {
    style = `body {-moz-user-select: none;
          -ms-user-select: none;
          -webkit-user-select: none;
          user-select: none;}` + style
  }
  if (nightMode) {
    style = `img {filter: invert(1);}` + style
  }
  let splitStyle = html.split("{{style}}");
  console.log('splitestyle0',splitStyle[0],);
  console.log('////////////');
  console.log('style',style);
  console.log('////////////');
  console.log('splitStyle1',splitStyle[1]);

  html = splitStyle[0] + style + splitStyle[1];
  return html;
}

function findHideIfEmpty(content, name, value, start) {
  if (start === undefined) {
    start = -1;
  }
  let startPos = content.indexOf("{{#" + name + "}}", start);
  if (startPos > -1) {
    if (value !== "") {
      content = content.replace("{{#" + name + "}}", "");
      content = content.replace("{{/" + name + "}}", "");
      let startPos = content.indexOf("{{#" + name + "}}", start);
      if (startPos > -1) {
        content = findHideIfEmpty(content, name, value);
      }
    } else {
      let endPos = content.indexOf("{{/" + name + "}}", startPos);
      if (endPos > 0) {
        content =
          content.substr(0, startPos) +
          content.substr(endPos + ("{{/" + name + "}}").length);
        if (content.indexOf("{{#" + name + "}}")) {
          content = findHideIfEmpty(content, name, value);
        }
      }
    }
  }
  return content;
}

function findHideIfHas(content, name, value, start) {
  if (start === undefined) {
    start = -1;
  }
  let startPos = content.indexOf("{{^" + name + "}}", start);
  if (startPos > -1) {
    if (value === "") {
      content = content.replace("{{^" + name + "}}", "");
      content = content.replace("{{/" + name + "}}", "");
      let startPos = content.indexOf("{{^" + name + "}}", start);
      if (startPos > -1) {
        content = findHideIfHas(content, name, value);
      }
    } else {
      let endPos = content.indexOf("{{/" + name + "}}", startPos);
      if (endPos > 0) {
        content =
          content.substr(0, startPos) +
          content.substr(endPos + ("{{/" + name + "}}").length);
        if (content.indexOf("{{^" + name + "}}", endPos)) {
          content = findHideIfHas(content, name, value, endPos);
        }
      }
    }
  }
  return content;
}

function findMedia(content) {
  let arr = content.match(/\[sound:([\w\S\s]+?)\]/gi);
  if (arr && arr.length > 0) {
    arr.forEach((v, k) => {
      let src = v.substring(7, v.length - 1);
      if (src.substr(0, 4) !== 'http') {
        src = '/' + src;
      }
      let id = Math.round(Math.random() * 10000000);
      let replace;
      if (src.indexOf(".mp4") > 0 || src.indexOf(".mov") > 0) {
        replace = `<video width="100%" controls><source src="${src}"></video>`;
      } else {
        if (k === 0) {
          replace = `<i class='iconfont icon-play'  onclick='mPlayAudio("${id}")' style='padding: 5px;font-size: 20px;cursor: pointer'><audio autoplay id='${id}' class='ak-play-audio'><source src="${src}"></audio></i>`;
        } else {
          replace = `<i class='iconfont icon-play'  onclick='mPlayAudio("${id}")' style='padding: 5px;font-size: 20px;cursor: pointer'><audio class='ak-play-audio' id='${id}'><source src="${src}"></audio></i>`;
        }
      }
      content = content.replace(v, replace);
    });
  }
  return content;
}

function findHint(content, name, value) {
  if (value !== '') {
    let id = Math.round(Math.random() * 10000000);
    let hint = `<a class=hint href= "#"
          onclick="this.style.display='none';document.getElementById('hint${id}').style.display='block';return false;">
          显示${name}
          </a >
          <div id="hint${id}" class=hint style="display: none">${value}</div>`;
    content = content.replace("{{hint:" + name + "}}", hint);
    if (content.indexOf("{{hint:" + name + "}}") > -1) {
      content = findHint(content, name, value);
    }
  } else {
    content = content.replace("{{hint:" + name + "}}", "");
  }
  return content;
}

function findType(cardStatus, content, name, value) {
  if (cardStatus === 'qfmt') {
    sessionStorage.setItem("type_input_value", "");
    let inputName = 'input_' + new Date().getTime();
    let replace = `<div style="text-align: center;margin-top: 20px">
<input id="${inputName}_answer" name="answer" style="width: 90%;padding: 10px" placeholder="请输入答案" onkeydown="mOnKeyDown()"/>
<script>
    let ${inputName}_answer = document.getElementById('${inputName}_answer');
    ${inputName}_answer.oninput = function(){
      sessionStorage.setItem("type_input_value",${inputName}_answer.value);
    }
<\/script>
`;
    content = content.replace("{{type:" + name + "}}", replace);
  } else {
    let inputValue = sessionStorage.getItem("type_input_value");
    if (inputValue === undefined || inputValue === null || inputValue === "") {
      inputValue = "&nbsp;&nbsp;&nbsp;";
    }
    let replace = "<div style='margin-top: 20px'><span style='padding: 5px;background-color: " + (inputValue === value ? '#28BEA0' : '#FF0000') + "'>" + inputValue + "</span></div>";
    replace += "<div style='margin: 10px'>↓</div>";
    replace += "<div><span style='padding: 5px;background-color: #f3f3f3'> " + value + "</span></div>";
    content = content.replace("{{type:" + name + "}}", replace);
  }
  return content;
}

export default renderHtml;
