let html = `
<!doctype html>
<html class="mobile android linux js">
    <head>
        <meta charset="utf-8">
        <link rel="stylesheet" type="text/css" href="https://amazingmemo.com/static/css/flashcard.css"/>
        <link rel="stylesheet" type="text/css" href="https://amazingmemo.com/static/css/anki-extend.css"/>
        <link rel="stylesheet" type="text/css" href="https://amazingmemo.com/static/css/layout.css"/>
        <script src="https://amazingmemo.com/static/js/jquery.min.js"></script>
        <script src="https://amazingmemo.com/static/js/anki-extend.js"></script>
        <script type="text/javascript" id="MathJax-script" src="https://amazingmemo.com/static/js/mathjax/tex-chtml.js"></script>
        <script>
        window.MathJax = {
          tex: {
            inlineMath:[['$$', '$$'], ['\\\\(', '\\\\)']]
          }
        };
        const linkEvent = {
          postMessage(json){
            if(typeof json == 'string'){
              data = JSON.parse(json);
            }else{
              data = json;
            }

            window.open(data.url)
          }
        }
        </script>
        <style>
        html {
          padding-bottom:100px
        }
        html::-webkit-scrollbar {
          width:0
        }
          img {
            max-width: 100% !important;
          }

          #content {
               margin-top: 0;
                margin-bottom: 0;
                padding-top: 55px;
                padding-bottom: 90px;
          }
        </style>
        <style>
            {{style}}
        </style>
    </head>
    <body class="card ck-content ::class::">
        <div id="content">
        {{content}}
        </div>
    </body>
    <script>
      let audioList = document.getElementsByClassName("ak-play-audio");
      if(audioList.length > 1){
        for(let i=0;i<audioList.length;i++){
          let index = i+1;
          audioList[i].addEventListener('ended', function() {
            if(i+1 < audioList.length){
              console.log(audioList[index]);
              audioList[index].play();
            }
          })
        }
      }

      setTimeout(function () {
        let test = document.getElementsByClassName('ak-cover');
        for (let i = 0; i < test.length; i++) {
          if(test[i].onclick == null){
            test[i].addEventListener('click', function(e){
              if (e.target.style.color == 'rgb(0, 0, 0)') {
                e.target.style.color = '#FFE5B4';
              } else {
                e.target.style.color = '#000000';
              }
            })
          }
        }
        let linkcard = document.getElementsByClassName('link-card');
        for (let i = 0; i < linkcard.length; i++) {
          let cardId = linkcard[i].getAttribute('data-lcid')
          console.log('>>>>>>>>>>> lcid',cardId);
          linkcard[i].onclick = function(){
            console.log('点击了');
           window.parent.postMessage({act:'linkCard',cardId});
          }
        }

        let mention = document.getElementsByClassName('mention');
        for (let i = 0; i < mention.length; i++) {
          let tag = mention[i].innerText;
          mention[i].onclick = function(){
            console.log('点击了');
           window.parent.postMessage({act:'mentionTag',tag});
          }
        }

        let svgs = document.getElementsByTagName('svg');
        for (let i = 0; i < svgs.length; i++) {
          let gs = svgs[i].getElementsByTagName('g');
          console.log(gs);
          for (let j = 0; j < gs.length; j++) {
            let g = gs[j];
            g.onclick = function(){
              if(g.style.opacity == 0.1){
                g.style.opacity = 1;
              }else{
                g.style.opacity = 0.1;
              }
            }
          }
        }
      }, 350);

      setTimeout(()=>{
          let aList = document.getElementsByTagName('a');
          for (let i = 0; i < aList.length; i++) {
            let url = aList[i].getAttribute('href');
            if(url && url.substr(0,"javascript".length) != 'javascript'){
              aList[i].setAttribute('target','_blank');
            }
          }
      },1000)
    </script>
    <script>
      {{script}}
    </script>
</html>
`;

export default html;
