<template>
  <div>
    <el-dialog width="900px" :visible.sync="showDialog" :close-on-click-modal="false"
               :close-on-press-escape="false" @before-close="beforeClose">
      <div class="cursor-pointer" slot="title" @click="showDialog = false">
        返回上一步
      </div>
      <div style="margin-top: -20px" class="justify-content-space-between">
        <div @click="onSelectChapterClick" class="cursor-pointer align-items-center fs-14">
          <svg-icon iconClass="card_edit_box" class="w-20 h-20"></svg-icon>
          <span class="mg-l-10" v-if="Object.keys(chapter).length>0">{{chapter.name}}</span>
          <span class="mg-l-10 c-c" v-else>选择卡牌盒</span>
        </div>
        <div>
          <span class="mg-r-20">已识别出 <span v-if="chapterList.length > 0">{{chapterList.length}} 个目录，</span> {{cardList.length}} 张卡片</span>
          <el-button class="w-100" :disabled="cardList.length === 0 && chapterList.length === 0" type="primary"
                     size="mini" @click="onConfirmClick">
            确认导入
          </el-button>
        </div>
      </div>
      <div class="mg-t-20 scroll-box" style="overflow-y: scroll;height: 500px;overflow-x: hidden;">
        <el-row :gutter="20">
          <el-col :span="12" :key="index" v-for="(card,index) in cardList">
            <el-card class="mg-b-20 cursor-pointer">
              <div @click="onCardClick(index)">
                <div class="h-120">
                  <div :class="card.fld2?'text-ellipsis-2':'text-ellipsis-5'" class="fs-16 text-strong text-ellipsis-2 mg-b-14">
                    {{card.fld1}}
                  </div>
                  <div v-if="card.fld2" class="fs-14 text-ellipsis-3">
                    {{card.fld2}}
                  </div>
                </div>
                <div class="row-reverse">
                  <div class="align-items-center">
                    <svg-icon iconClass="new_card" class="w-16 h-16 mg-r-4"></svg-icon>
                    <div class="c-b fs-12">新卡</div>
                  </div>
                </div>
              </div>
            </el-card>
          </el-col>
        </el-row>
      </div>
    </el-dialog>
  </div>
</template>

<script>


export default {
  name: "ImportPreview",
  props: {
    chapter: {
      type: Object,
      default: () => {
      }
    },

    cardList: {
      type: Array,
      default: () => []
    },
    chapterList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      showDialog: false,
      doImport: false,
    }
  },
  methods: {
    onCardClick(index) {
      this.$emit('onItemClick', index);
    },
    open() {
      this.showDialog = true;
    },
    close() {
      this.showDialog = false;
    },
    onSelectChapterClick() {
      this.$emit('onSelectChapterClick');
    },
    onConfirmClick() {
      if (this.cardList.length == 0) {
        return;
      }

      this.$emit('onConfirmImportClick')
    },
    beforeClose() {
      this.doImport = false;
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep .el-card__body {
  padding: 24px;
}
.scroll-box {
  scrollbar-width: none;
  &::-webkit-scrollbar {
    width: 0;
  }
}
</style>
