import LinkTagIcon from '../assets/images/editorTool/linktag.png'
import CoverIcon from '../assets/images/editorTool/cover.png'
import CodeBlockIcon from '../assets/images/editorTool/codeblock.png'
import ImageIcon from '../assets/images/editorTool/image.png'
import TableIcon from '../assets/images/editorTool/table.png'
import SourceIcon from '../assets/images/editorTool/source.png'
import CleanIcon from '../assets/images/editorTool/clean.png'
import QuoteIcon from '../assets/images/editorTool/quote.png'
import LinkCardIcon from '../assets/images/editorTool/linkcard.png'


function makeToolObj() {
  return {
    linktag: {
      icon: LinkTagIcon,
      value: '',
      active: false,
      title: '标签',
      name: 'linktag'
    },
    linkcard: {
      icon: LinkCardIcon,
      value: '',
      active: false,
      title: '双链',
      name: 'linkcard'
    },
    cover: {
      icon: CoverIcon,
      value: '',
      active: false,
      title: '遮挡',
      name: 'cover'
    },
    image: {
      icon: ImageIcon,
      value: '',
      active: false,
      title: '图片',
      name: 'image'
    },
    insertTable: {
      icon: TableIcon,
      value: '',
      active: false,
      title: '表格',
      name: 'insertTable'
    },
    blockQuote: {
      icon: QuoteIcon,
      value: '',
      active: false,
      title: '引用',
      name: 'blockQuote'
    },
    codeBlock: {
      icon: CodeBlockIcon,
      value: '',
      active: false,
      title: '代码',
      name: 'codeBlock'
    },
    sourceEditing: {
      icon: SourceIcon,
      value: '',
      active: false,
      title: '源码',
      name: 'sourceEditing'
    },
    removeFormat: {
      icon: CleanIcon,
      value: '',
      active: false,
      title: '清除格式',
      name: 'removeFormat'
    },
  }
}

function handlerEvent(e, maps, editors, currentEditorIndex) {
  if (e.tool.name === 'linktag') {
    editors[currentEditorIndex].model.change(writer => {
      editors[currentEditorIndex].model.insertContent(writer.createText(' #'));
    });
  } else if (e.tool.name === 'linkcard') {
    editors[currentEditorIndex].model.change(writer => {
      editors[currentEditorIndex].model.insertContent(writer.createText(' @'));
    });
  } else if (e.tool.name === 'image') {
    editors[currentEditorIndex].execute('uploadImage', e.params);
  } else if (e.tool.name === 'sourceEditing') {
    if (e.tool.active) {
      maps.forEach(tool => {
        tool.sourceEditing.active = false;
      })
    } else {
      maps.forEach(tool => {
        tool.sourceEditing.active = true;
      })
    }

    editors.forEach(editor => {
      if (editor) {
        editor.execute(e.tool.name)
      }
    });

    editors[0].focus();
  } else {
    if (e.params) {
      editors[currentEditorIndex].execute(e.tool.name, e.params);
    } else {
      editors[currentEditorIndex].execute(e.tool.name);
    }
  }
}

export default {makeToolObj, handlerEvent}
